import React from 'react';
import Suggestion from './Suggestion';

export default function Response(props){
  return (
    <Suggestion
      selected={props.selected}
      color={props.color}
      onClick={props.onClick}
      >
      {props.children}
    </Suggestion>
  );
}
