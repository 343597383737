import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import '../App.css';
import Button from './ui/Button';

export default class Help extends React.Component {

  render(){
    const { color, onContinue } = this.props;
    return (
      <div>
          <br />
          <Button outline color={color} onClick={ () => {
              onContinue();
            }}> Close Help Screen </Button>
          <Heading color={color} style={{marginTop: 5}}>Welcome To Explicit Idiots!</Heading>
          <p>
            This game is a twist on the classic Cards Against Humanity Game!
            In Explicit Idiots,
          <span style={{color, fontWeight: 500}}> YOU CREATE THE DECK! </span>
            Whenever You Submit a Prompt or Response,
            it gets added to the deck for future players to use!
          </p>
          <SubHeading color={color}>How To Start</SubHeading>
          <p>
            Simply select Host to start a game.
            You can invite players by sending them a link to your game.
            The link should look something like this:
            <code> explicitidiots.com/CODE </code>
          </p>
          <SubHeading color={color}>Rules</SubHeading>
          <p>
            This game is intended to make people laugh, so
            if you are offended by any material in this game,
            simply stop being a pussy.
          </p>
          <p>
            Any cards that contain private information, malicious links, or spam
            will be removed immediately.
          </p>
          <SubHeading color={color}>Privacy</SubHeading>
          <p>
            This game does not require a user to sign in or store any personal information.
            It is your responsibility to keep your private information to yourself.
            We will never ask for it.
          </p>
          <Button style={{marginTop:10}} color={color} onClick={ () => {
              onContinue();
            }}> Close Help Screen </Button>

        </div>
    );
  }
}
