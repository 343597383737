import React from 'react';
import '../../App.css';
import { Row, Col } from 'react-grid-system';

function Circle(props){
    var circleStyle = {
      margin: 7,
      display:"inline-block",
      border: 'calc(3px + 0.5vmin) solid ' + props.color,
      backgroundColor: props.color,
      borderRadius: "50%",
      width: 'calc(12px + 2vmin)',
      height:'calc(12px + 2vmin)',
    };
    return (
        <div style={circleStyle}>
        </div>
    );
}

function OutlinedCircle(props){
    var circleStyle = {
      margin: 7,
      display:"inline-block",
      border: 'calc(3px + 0.5vmin) solid ' + props.color,
      backgroundColor: `${props.color}40`,
      borderRadius: "50%",
      width: 'calc(12px + 2vmin)',
      height:'calc(12px + 2vmin)',
    };
    return (
        <div style={circleStyle}>
        </div>
    );
}

export default function Nametag(props){
  return (
    <Row>
      <Col xs={12}>
        {props.ready ?
          <Circle color={props.color} /> :
          <OutlinedCircle color={props.color} />
        }
        <span
          className={
            props.scoreboard ?
            'scoreboardNametag' :
            'nametag'
          }
          style={
            props.style ?
            Object.assign({cursor: 'default'}, props.style) :
            {cursor: 'default'}
          }
          >
          {props.name}
        </span>
      </Col>
    </Row>
  );
}
