import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import Suggestion from './ui/Suggestion';
import Prompt from './ui/Prompt';
import '../App.css';
import Input from './ui/Input';
import Button from './ui/Button';
import Nametag from './ui/Nametag';
import Response from './ui/Response';
import RefreshButton from './ui/RefreshButton';
import {
  submitResponse,
  submitResponseKey,
  recordResponseActivity,
  getRandomResponse,
  stageResponse,
  undoResponse,
  startJudgePhase,
  lobbyUnReady
} from '../firebase.js'

import { FiEdit2 as EditIcon } from 'react-icons/fi';

export default class ResponsePhase extends React.Component {

  state = {
    timesUp: false
  }


  /**
    Response Timeout and setTimer are being hidden because they
    auto send players to lobby

    This is being replaced with a system that give players option
    to skip other plyers turns

    This may be brought back as an optional Game Setting
  **/

  /**
  responseTimeout(){
      console.log('Times Up!!!')
      const { game, gameID, player, players } = this.props;
      const { response, settings, status } = game;

      console.log('status.phase = ' + status.phase);
      if(status.phase === 'Response'){

        //to get all players who have not submitted responses
        //compare players keys to submissions keys
        const playerIDs = Object.keys(players);
        const playersSubmitted = response.submissions ? Object.keys(response.submissions) : [];
        const playersNotSubmitted =
          playerIDs.filter(playerID =>
            playerID !== status.judge && !playersSubmitted.includes(playerID)
          );
        const playerActivity = response.activity ? response.activity : {};

        // for all players whom have not submitted their responses
        console.log('PLAYERS NOT SUBMITTED : \n' + JSON.stringify(playersNotSubmitted))
        playersNotSubmitted.forEach(playerID => {
          // if player has been active since response.timestamp,
          // then give them a random response
            if( playerActivity[playerID] &&
              Number.isInteger(playerActivity[playerID]) &&
              playerActivity[playerID] > response.timestamp
            ){
              getRandomResponse(response => {
                stageResponse(gameID, {id: playerID}, response);
              });
            }
          // if player has not been active
          // send them back to lobby
            else{
              lobbyUnReady(gameID, {id: playerID});
            }
        });

        // after for loop start judge phase
        startJudgePhase(gameID);
      }

  }

  setTimer(){
      const { response, settings } = this.props.game;
      if(parseInt( response.timestamp, 10 ) > 0){
        console.log('timestamp: ' + parseInt( response.timestamp, 10 ));
        console.log('maxResponseTime: ' + parseInt( settings.maxResponseTime, 10 ));
        const maxTime =
          parseInt( response.timestamp, 10 ) + parseInt( settings.maxResponseTime, 10 );
        console.log('maxTime: ' + maxTime);
        var timeLeft = maxTime - Date.now();
        console.log('timeLeft: ' + timeLeft);
        if(timeLeft > 0){
          this.timer = setTimeout(
            () => {
              this.setState({timesUp: true});
            },
            timeLeft
          );
        }
        else{
          this.setState({timesUp: true});
        }
      }
  }
  **/

  processSubmissions(){
      const { gameID, player, nav, game, players } = this.props;

      if(players && game && game.response.submissions){
        const playerKeys = Object.keys(players);
        const allSubmissionsLength = playerKeys.includes(game.status.judge) ?
          playerKeys.length - 1 :
          playerKeys.length
        const allSubmissions =
          allSubmissionsLength === Object.keys(game.response.submissions).length;

        if( allSubmissions ){
          //clearTimeout(this.timer);
          startJudgePhase(gameID);
        }

      }
  }

  componentDidMount(){
    /**
      this.responseTimeout = this.responseTimeout.bind(this);
      this.setTimer = this.setTimer.bind(this);
      this.setTimer();
    **/
      this.processSubmissions = this.processSubmissions.bind(this);
      this.processSubmissions();

  }

  componentDidUpdate(prevProps, prevState){
    const { game, players } = this.props;

    if(
      JSON.stringify(prevProps.game.response.submissions) !==
      JSON.stringify(game.response.submissions) ||
      JSON.stringify(prevProps.players) !==
      JSON.stringify(players)
    ){
      this.processSubmissions();
    }

    if(
      prevProps.game.response.timestamp !== game.response.timestamp ||
      prevProps.game.settings.maxResponseTime !== game.settings.maxResponseTime
    ){
      /**
      clearTimeout(this.timer);
      this.setTimer();
      **/
    }

    if( prevState.timesUp !== this.state.timesUp ){
      if(this.state.timesUp){
        //this.responseTimeout();
      }
    }
  }

  render(){
    const { gameID, player, nav, game, players } = this.props;
    if(game === undefined){
      return(<div></div>)
    }
    const isJudge = game.status.judge === player.id;
    const isPlayer = !isJudge;

    if(isJudge){
      return (
        <JudgeWaiting
          gameID={gameID}
          player={player}
          nav={nav}
          game={game}
          players={players}
          />
      )
    }
    if(isPlayer){
      return (
        <PlayerPlaying
          gameID={gameID}
          player={player}
          nav={nav}
          game={game}
          players={players}
          />
      )
    }
  }
}

class JudgeWaiting extends React.Component {

  render(){
    const { gameID, game, player, nav, players } = this.props;
    const { color } = player;

    const judge = player;
    const prompt = game.prompt.prompt;
    const ready = game.response.submissions ?
      Object.keys(game.response.submissions) :
      [];

    return (
      <div>
          <Heading color={color}>Waiting for Players</Heading>
          <Prompt color={color} prompt={prompt} />
          {Object.keys(players).map((id, i) => (
            <div key={id} >
              {id !== player.id &&
                <Nametag ready={ready.includes(id)} color={players[id].color} name={players[id].name} />
              }
            </div>
          ))}
      </div>
    );
  }
}

class PlayerPlaying extends React.Component {
  state={
    input: '',
    suggestions: [],
    loading: false,
    prompt: '',
    responseKey: ''
  }

  async getResponseKey(){
    const { gameID, game } = this.props;
    var responseKey = localStorage.getItem('responseKey');
    if(responseKey){
      console.log(responseKey);
      responseKey= JSON.parse(responseKey);
      var isValidKey =
        responseKey.round === game.status.round &&
        responseKey.gameID === gameID;
      console.log('valid? ' + isValidKey);
      if(isValidKey){
        this.setState({responseKey: responseKey.key})
        return responseKey.key;
      }
      else{
        var key = await this.newResponseKey();
        return key;
      }
    }
    else{
      var key = await this.newResponseKey();
      return key;
    }
  }

  async newResponseKey(){
    const { gameID, game } = this.props;
    var key = await submitResponseKey();
    this.setState({responseKey: key});

    var responseKey = {gameID, round: game.status.round, key};
    responseKey = JSON.stringify(responseKey);
    localStorage.setItem('responseKey', responseKey);
    return key;
  }

  addSuggestion(suggestion){
    let array = this.state.suggestions;
    if(array.includes(suggestion)){
      return false;
    }
    array.push(suggestion);
    this.setState({suggestions: array});
    return true;
  }

  async getSuggestions(){
    await this.setState({suggestions: []});
    await getRandomResponse(this.addSuggestion.bind(this));
    await getRandomResponse(this.addSuggestion.bind(this));
    await getRandomResponse(this.addSuggestion.bind(this));
  }

  async componentDidMount(){

    this.getResponseKey = this.getResponseKey.bind(this);
    this.newResponseKey = this.newResponseKey.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    await this.getSuggestions();


    const { game, player } = this.props;
    if(game.response.submissions && this.state.input === ''){
      const ready = Object.keys(game.response.submissions);
      const isReady = ready.includes(player.id);
      const playerResponse = game.response.submissions[player.id];
      this.setState({input: playerResponse});
    }

  }

  async submit(){
    this.setState({loading: true});
    const {gameID, player} = this.props;
    var {responseKey} = this.state;
    if( !responseKey || responseKey === ''){
      responseKey = await this.getResponseKey();
    }
    var response = this.state.input.trim();
      var exists = response.length > 1;
      if( exists ){
        stageResponse(gameID, player, response);
        submitResponse(responseKey, response);
        this.setState({loading: false});
      }
      else {
        this.setState({loading: false});
      }

  }

  render(){
    const { suggestions, loading, input } = this.state;
    const { game, player, nav, gameID, players } = this.props;
    const { color } = player;
    const submit = this.submit.bind(this);
    const judge = game.players[game.status.judge];
    const prompt = game.prompt.prompt;

    const ready = game.response.submissions ?
      Object.keys(game.response.submissions) :
      [];
    const isReady = ready.includes(player.id);

    if(isReady){
      return (
        <PlayerDone
          gameID={gameID}
          player={player}
          nav={nav}
          game={game}
          players={players}
          />
      )
    }

    return (
      <div>
        {judge &&
          <div>
            <Heading color={judge.color}>{'Judge ' +judge.name}</Heading>

            <Prompt color={judge.color} prompt={prompt} />
          </div>
        }

          <SubHeading color={color}>Choose A Response</SubHeading>
          <RefreshButton
            color={color}
            onClick={() => {
              recordResponseActivity(gameID, player);
              this.getSuggestions();
            }}
          />
          {suggestions.map((sugg, k) => (
            <Suggestion
              key={k}
              selected={sugg === input}
              color={color}
              onClick={ () => {
                recordResponseActivity(gameID, player);
                this.setState({input: sugg});
              }}
            >
              {sugg}
            </Suggestion>
          ))}

          <SubHeading color={color}>Write Your Own</SubHeading>
          <Input
            multi
            placeholder='Write Your Own Response'
            onChange={ (e) => {
              recordResponseActivity(gameID, player);
              this.setState({input: e.target.value});
            }}
            value={input}
            style={{
              fontSize: 'calc(12px + 2vmin)'
            }}
            />
          <Button
            disabled={loading}
            color={color}
            onClick={ () => {
              submit();
            }}
            >
            Ready
          </Button>

      </div>
    );
  }
}

class PlayerDone extends React.Component {
  render(){
    const { gameID, game, player, nav, players } = this.props;
    const { color } = player;

    const judgeID = game.status.judge;
    const judge = game.players[judgeID];
    const prompt = game.prompt.prompt;
    const ready = game.response.submissions ?
      Object.keys(game.response.submissions) :
      [];
    const playerResponse = game.response.submissions[player.id];

    return (
      <div>
        {judge &&
          <div>
            <Heading color={judge.color}>{'Judge ' +judge.name}</Heading>

            <Prompt color={judge.color} prompt={prompt} />
          </div>
        }
          <SubHeading color={color}>Your Response</SubHeading>
          <Response
            selected={false}
            color={color}
            onClick={() => {}}
            >
            {playerResponse}
          </Response>
          <Button
            link
            color={color}
            onClick={ () => {
              undoResponse(gameID, player);
            }}
            style={{
              textAlign: 'center'
            }}
            >
            <span style={{position:'relative', top:4}}>
              <EditIcon />
            </span>
            {' Edit Response'}
          </Button>
          <br/>

          {Object.keys(players).map((id, i) => (
            <div key={id} >
              {id !== judgeID &&
                <Nametag ready={ready.includes(id)} color={players[id].color} name={players[id].name} />
              }
            </div>
          ))}


      </div>
    );
  }
}
