import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import { Container, Row, Col, Visible } from 'react-grid-system';
import Button from './ui/Button';
import GameIDInput from './ui/GameIDInput';
import '../App.css';
import './Home.css';
import { hostGame } from '../firebase.js';

import { BiSitemap, BiWorld } from "react-icons/bi"
import { IoIosColorPalette, IoMdSend } from 'react-icons/io'

export default class Home extends React.Component {
  state = {
    selected: '',
    gameIDInput: ''
  };

  handleInput(val){
    var gameIDInput = val.toUpperCase().substring(0,4);
    this.setState({gameIDInput});
  }

  createGame(){
    const { nav, state } = this.props;
    const { selected } = this.state;
    if(selected==='host'){
      var player = {
        id: state.id,
        color: state.color,
        name: state.name
      };
      hostGame(
        player,
        (gameID) => {
          if(gameID !== ''){
            nav('Lobby');
            window.location.pathname = '/'+gameID;
          }
          else{
            console.log('fail');
          }
        }
      );
    }
  }

  componentDidMount(){
    this.createGame = this.createGame.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  render(){
    const { color, nav, state } = this.props;
    const { selected, gameIDInput } = this.state;
    return (
      <div>
        <br />
        <BackToNameColor color={color} nav={nav} />
        <SubHeading color={color}>
          Alpha v0.2.1
        </SubHeading>
        <br/>
        <Row>
          <HostButton
            color={color}
            nav={nav}
            selected={selected === 'host'}
            onClick={() => {
              selected === 'host' ?
              this.setState({selected: ''}) :
              this.setState({selected: 'host'});
              setTimeout(function(){this.createGame();}.bind(this), 1000);
            }}
            />
          <JoinButton
              color={color}
              nav={nav}
              selected={selected === 'join'}
              onClick={() => {
                selected === 'join' ?
                this.setState({selected: ''}) :
                this.setState({selected: 'join'});
              }}
              />
        </Row>
        {selected === 'join' &&
          <EnterGameID
            color={color}
            onChange={(e)=>{
              this.handleInput(e.target.value);
            }}
            onSubmit={()=>{
              nav('Lobby');
              window.location.pathname = '/'+gameIDInput;
            }}
            value={gameIDInput}
            />
        }
      </div>
    );
  }
}

function BackToNameColor(props){
  const {color, nav} = props;
  return (
    <Button
      outline
      color={color}
      onClick={ () => {
        nav('ChangeNameColor')
      }}
    >
      <span style={{position:'relative', top:4}}>
        <IoIosColorPalette />
      </span> Back To Change Name/Color
    </Button>
  )
}

function HostButton(props){
  const {color, nav, selected, onClick} = props;
  return (
    <HomeButton
      color={color}
      nav={nav}
      selected={selected}
      onClick={onClick}
      Icon={
        <BiSitemap />
      }
      label='Host'
      />
  )
}

function JoinButton(props){
  const {color, nav, selected, onClick} = props;
  return (
    <HomeButton
      color={color}
      nav={nav}
      selected={selected}
      onClick={onClick}
      Icon={
        <BiWorld />
      }
      label='Join'
      />
  )
}

function HomeButton(props){
  const {color, nav, selected, onClick, Icon, label} = props;
  return (
      <Col xs={6}>
        <div
          className={selected ? "HomeButtonSelected" : "HomeButton"}
          style={{
            '--color': color,
            '--color-trans': color+'B0',
          }}
          onClick={onClick}
        >
        <div className="HomeIcon">
          {Icon}
        </div>
        {label} </div>
      </Col>
  )
}

function EnterGameID(props){
  const { color, onChange, value, onSubmit } = props;
  return (
    <Row
      style={{marginLeft: 'default', marginRight: 'default'}}>
      <GameIDInput
        style={{display: 'inline-block'}}
        label='Enter Game ID:'
        color={color}
        onChange={onChange}
        value={value}
        autoFocus
        onSubmit={onSubmit}
        />
      <span
        style={{
          color,
          fontWeight: 500,
          fontSize: 'calc(12px + 3vmin)',
          position: 'relative',
          left: '2vmin',
          top: 'calc(22px - 1vmin)',
          cursor: 'pointer'
        }}
        onClick={onSubmit}
        >
          <IoMdSend />
      </span>
    </Row>
  )
}
