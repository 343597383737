import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import Nametag from './ui/Nametag';
import '../App.css';
import Button from './ui/Button';
import { Row, Col } from 'react-grid-system';

import {
  voteToKick
} from '../firebase.js';

export default class VoteKickMenu extends React.Component {

  state = {
    playersInGame: [],
    playersInLobby: []
  }
  //if a vote to kick exists, then present a menu for just kicking that person
  //if not, show menu of all players with a vote to kick option next to name
  //show players in lobby and players in game on seperate lists

  updatePlayersList(){
      const { players, game, player, gameID } = this.props;
      const allPlayers = Object.keys(game.players);
      const playersInGame = game.status.round === 0 ? [] : Object.keys(players);
      var playersInLobby = [];
      allPlayers.forEach( player => {
        if(!playersInGame.includes(player)){
          playersInLobby.push(player);
        }
      });

      this.setState({playersInGame, playersInLobby});
  }

  componentDidMount(){
    this.updatePlayersList = this.updatePlayersList.bind(this);
    this.updatePlayersList();

    if(Object.keys(this.props.game.players).length <= 1){
      this.props.nav('Lobby');
    }
  }

  componentDidUpdate(prevProps){
    const { players } = this.props;
    if(
      JSON.stringify(prevProps.players) !==
      JSON.stringify(players)
    ){
      this.updatePlayersList();
    }
  }

  render(){
    const { players, game, player, gameID } = this.props;
    const { color } = player;
    const { playersInGame, playersInLobby } = this.state;
    return (
      <div>
        <Heading color={color}>
          Kick Player
        </Heading>
          <br/>
        <Button
          outline
          color={player.color}
          onClick={()=>{
            this.props.nav('Lobby');
          }}
          >
          Cancel
        </Button>
        {playersInLobby.length > 0 &&
          <SubHeading color={color}>
            In Lobby
          </SubHeading>
        }
        {playersInLobby.map(playerID => (
          <SelectPlayer
            inGame={false}
            playerID={playerID}
            selectPlayer={game.players[playerID]}
            player={player}
            gameID={gameID}
            onClick={()=>{
              voteToKick(gameID, player, playerID);
              this.props.nav('Lobby');
            }}
            />
        ))}
        {playersInGame.length > 0 &&
          <SubHeading color={color}>
            In Game
          </SubHeading>
        }
        {playersInGame.map(playerID => (
          <SelectPlayer
            inGame={true}
            playerID={playerID}
            selectPlayer={game.players[playerID]}
            player={player}
            gameID={gameID}
            onClick={()=>{
              voteToKick(gameID, player, playerID);
              this.props.nav('Lobby');
            }}
            />
        ))}
      </div>
    )
  }
}

function SelectPlayer(props){
  const { inGame, playerID, selectPlayer, player, onClick } = props;
  return (
      <div key={playerID}>
      {playerID !== player.id &&
        <div
          onClick={onClick}
          style={{cursor: 'pointer'}}
          >
          <Nametag
            color={selectPlayer.color}
            ready={inGame}
            name={selectPlayer.name}
            scoreboard
            style={{cursor: 'pointer'}}
            />
        </div>
      }
      </div>
  )
}
