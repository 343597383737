import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import Suggestion from './ui/Suggestion';
import Prompt from './ui/Prompt';
import Response from './ui/Response';
import '../App.css';
import Input from './ui/Input';
import Button from './ui/Button';

import {
  winningResponse,
  favoriteResponse,
  undoWinningResponse,
  undoFavoriteResponse,
  startScoreboardPhase
} from '../firebase.js';

export default class Judge extends React.Component {
  state={
    submissionIDs: [],
    shuffled: false,
    minTimeReached: false,
    maxTimeReached: false
  }

  /**
    setTimers are being hidden because they
    auto send players to lobby

    This is being replaced with a system that give players option
    to skip other plyers turns

    This may be brought back as an optional Game Setting
  **/

  /**
  setTimers(){
    const { judge, settings } = this.props.game;
      if(parseInt( judge.timestamp, 10 ) > 0){

        const minTime =
          parseInt( judge.timestamp, 10 ) + parseInt( settings.minJudgeTime, 10 );
        const maxTime =
          parseInt( judge.timestamp, 10 ) + parseInt( settings.maxJudgeTime, 10 );

        var minTimeLeft = minTime - Date.now();
        var maxTimeLeft = maxTime - Date.now();

        if(minTimeLeft > 0){
          this.minTimer = setTimeout(
            () => {
              this.setState({minTimeReached: true});
            },
            minTimeLeft
          );
        }
        else{
          this.setState({minTimeReached: true});
        }

        if(maxTimeLeft > 0){
          this.maxTimer = setTimeout(
            () => {
              this.setState({maxTimeReached: true});
            },
            maxTimeLeft
          );
        }
        else{
          this.setState({maxTimeReached: true});
        }
      }
  }
  **/

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  componentDidMount(){

    if(!this.state.shuffled){
      const game = this.props.game;
      var submissionIDs = this.shuffle(Object.keys(game.response.submissions));

      if(submissionIDs.length > 0){
        this.setState({ shuffled: true });
      }
      this.setState({ submissionIDs });
    }

    //-- Use Set Timers to activate kick on time setTimeout
    /*
    ** this.setTimers = this.setTimers.bind(this);
    ** this.setTimers();
    */

  }

  componentDidUpdate(prevProps, prevState){

    const { game } = this.props;
    const { minTimeReached, maxTimeReached } = this.state;

    if(
      JSON.stringify(prevProps.game.response.submissions) !==
      JSON.stringify(game.response.submissions)
    ){
      if(!this.state.shuffled){
        const game = this.props.game;
        var submissionIDs = this.shuffle(Object.keys(game.response.submissions));

        if(submissionIDs.length > 0){
          this.setState({ shuffled: true });
        }
        this.setState({ submissionIDs });
      }
    }

    if(
      prevProps.game.judge.winner !== game.judge.winner
      // || prevState.minTimeReached !== minTimeReached
    ){
      // if( minTimeReached ){
        const winner = game.judge.winner;
        if( winner ){
          const submitted = Object.keys(game.response.submissions);
          if(submitted.includes(winner)){
            startScoreboardPhase(this.props.gameID);
          }
        }
      // }
    }

    if(
      prevProps.game.judge.winner !== game.judge.winner ||
      prevState.maxTimeReached !== maxTimeReached ||
      JSON.stringify(prevProps.game.judge.favorites) !==
      JSON.stringify(game.judge.favorites)
    ){
      if( maxTimeReached ){

        const winner = game.judge.winner;
        if( winner ){
          const submitted = Object.keys(game.response.submissions);
          if(submitted.includes(winner)){
            startScoreboardPhase(this.props.gameID);
          }
        }

        if(
          game.judge.favorites &&
          Object.keys(game.judge.favorites).length > 0
        ){
          startScoreboardPhase(this.props.gameID);
        }

      }
    }

    if(
      prevProps.game.judge.timestamp !== game.judge.timestamp ||
      prevProps.game.settings.maxJudgeTime !== game.settings.maxJudgeTime||
      prevProps.game.settings.minJudgeTime !== game.settings.minJudgeTime
    ){
      /**
      clearTimeout(this.minTimer);
      clearTimeout(this.maxTimer);
      this.setTimers();
      **/
    }
  }

  render(){
    const {
      gameID,
      player,
      nav,
      game,
      players
    } = this.props;
    const { submissionIDs, shuffled } = this.state;
    const { color } = player;
    const { status, response: responsePhase, judge: judgePhase } = game;
    const judge = game.players[status.judge];
    const isJudge = status.judge === player.id;
    const notJudge = !isJudge;
    const prompt = game.prompt.prompt;
    const favorites = judgePhase.favorites ? judgePhase.favorites : {};

    if(judge && judgePhase !== undefined){
      return (
        <div>
            <Heading color={judge.color}>
              {
                isJudge ?
                'Choose Winner':
                'Judge ' + judge.name
              }
            </Heading>
            <Prompt color={judge.color} prompt={prompt} />
            { notJudge && Object.keys(responsePhase.submissions).includes(player.id) &&
              <div>
                <SubHeading color={color}>Your Response</SubHeading>
                <Response
                  selected={false}
                  color={color}
                  onClick={ () => {
                  }}>
                  {responsePhase.submissions[player.id]}
                </Response>
              </div>
            }
            {
              notJudge &&
                <SubHeading color={color}>Choose A Favorite</SubHeading>
            }
            {
              submissionIDs.map((submissionID, i) => (
              <div key={i}>
                {(submissionID !== player.id) &&
                  <Submission
                      isJudge={isJudge}
                      id={submissionID}
                      selected={
                        isJudge ?
                        submissionID === judgePhase.winner :
                        submissionID === favorites[player.id]
                      }
                      player={player}
                      gameID={gameID}
                  >
                    {responsePhase.submissions[submissionID]}
                  </Submission>
                }
              </div>
            ))
          }

        </div>
      );
    }
    else{
      return (
        <div></div>
      );
    }

  }
}

class Submission extends React.Component {
  handleClick(){
    const { selected, isJudge, gameID, player, id } = this.props;
    if(isJudge){
      if(selected){
        undoWinningResponse(gameID, player, id);
      }
      else{
        winningResponse(gameID, player, id);
      }
    }
    else{
      if(selected){
        undoFavoriteResponse(gameID, player, id);
      }
      else{
        favoriteResponse(gameID, player, id);
      }
    }
  }

  render(){
    const { isJudge, id, selected, player, children, gameID } = this.props;
    const { color } = player;
    const handleClick = this.handleClick.bind(this);
      return (
        <Response
          selected={selected}
          color={color}
          onClick={handleClick}
        >
            {children}
        </Response>
      )

  }
}
