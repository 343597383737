import React from 'react';
import Button from './Button';
import { BiRefresh } from 'react-icons/bi';

export default function RefreshButton(props){
    const { color, onClick } = props;
    return (
      <Button
        link
        color={color}
        style={{
          display: 'block',
          margin: 0,
          position: 'relative',
          textAlign: 'right',
          padding: 0
        }}
        onClick={onClick}
        >
        {'Refresh '}
        <span style={{position:'relative', top:4}}>
          <BiRefresh />
        </span>
      </Button>
    )
}
