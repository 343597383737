import React from 'react';
import Button from './Button';
import { Heading, SubHeading } from './Headings';
import AnimateHeight from 'react-animate-height';
import { Row, Col } from 'react-grid-system';
import {
  leaveGame,
  resetKickVote,
  voteKickBallot
} from '../../firebase';

export default class KickPlayerDropdown extends React.Component {

  state={
    openKickPlayerDropdown: false,
    timesUp: false,
    timeLimit: 20000, //1000 * 20
  }

  //dropdown function:
  // determines if openKickPlayerDropdown should be true
  // -- vote timer is not expired
  // -- the vote to kick exists
  // -- player hasnt voted
  // -- not enough votes
  // determines if vote should be reset
  // -- everyone voted
  // -- vote expired
  // -- all votes submitted

  /**
    OBJECT STRUCTURE REFERENCE
    game.kick = {
      timestamp:
      kickPlayerID:
      votes:{
        [player.id]: true
      }
    }
  **/
  dropdown(){
    const { game, gameID, player } = this.props;
    const {timesUp} = this.state
    if(game.kick != null){
      const { votes, kickPlayerID } = game.kick;

      if( timesUp || votes == null || Object.keys(votes).length == 0 ){
        resetKickVote(gameID);
        this.setState({openKickPlayerDropdown: false});
      }
      else{
        const { totalVotes, votesToKick } = this.countVotesToKick(votes);
        //if player already voted, close box
        if(
          votes[player.id] != null &&
          votes[player.id] === true ||
          votes[player.id] === false
        ){
          this.setState({openKickPlayerDropdown: false});
        }
        //if enough votes to kick, kick and close dropdown
        else if( votesToKick >= 3 ){
          leaveGame(gameID, {id: kickPlayerID});
          this.setState({openKickPlayerDropdown: false});
          resetKickVote(gameID);
        }
        //if everyone voted, close dropdown
        else if( totalVotes.length == Object.keys(game.players).length ){
          this.setState({openKickPlayerDropdown: false});
        }
        //if player has not voted, not enough to kick and timeLeft
        // open dropdown
        else{
          this.setState({openKickPlayerDropdown: true});
        }
      }
    }
  }

  countVotesToKick(votes){
    //since the contents of the vote can either be true, false, or null
    //we have to count the exact amount of votes
    var votesToKick = 0;
    var totalVotes = 0;
    for (var voter in votes) {
      if (votes.hasOwnProperty(voter)) {
        totalVotes++;
        if (votes[voter])
          votesToKick++;
      }
    }
    return {votesToKick, totalVotes}
  }

  async setTimer(){
    //trigger timesUp if time has expired or if kick doesnt exist
    await this.setState({timesUp: false});
    const timeLimit = this.state.timeLimit;
    const game = this.props.game;

    if( game.kick ){
      const timeLeft = timeLimit + game.kick.timestamp - Date.now();
      console.log('timeLeft: ' + timeLeft);
      const expired = timeLeft <= 0;

      if(expired){
        await this.setState({timesUp: true});
        console.log('timesUp + TRUE');
      }
      else{
        console.log('timeOUT + TRUE');
        console.log(this.state.timesUp);
        setTimeout(
          ()=>{
            this.setState({timesUp: true})
          },
          timeLeft
        );
      }
    }
    else{
      await this.setState({timesUp: true});
      console.log('timesUp + TRUE');
    }

  }

  componentDidMount(){
    this.dropdown = this.dropdown.bind(this);
    this.dropdown();

    this.setTimer = this.setTimer.bind(this);
    this.setTimer();
  }

  async componentDidUpdate(prevProps, prevState){
    const { game } = this.props;
    if(
      game.kick &&
      prevProps.game.kick &&
      prevProps.game.kick.timestamp !== game.kick.timestamp
    ){
      console.log('timer!!')
      await this.setTimer();
    }
    if(
      game.kick
      &&
      JSON.stringify(prevProps.game.kick) !==
      JSON.stringify(game.kick)
    ){
      console.log('dropdown!!')
      await this.setTimer();
      this.dropdown();
    }
    else if( prevState.timesUp !== this.state.timesUp ){
      console.log('timesup dropdown!!')
      this.dropdown();
    }
    if(prevState.openKickPlayerDropdown !== this.state.openKickPlayerDropdown){
      console.log('dropdown state: ' + this.state.openKickPlayerDropdown);
    }
  }

  render(){
    const { game, player, gameID } = this.props;
    const { openKickPlayerDropdown } = this.state;
    const { color } = player;
    if( game.kick && game.players && game.players[game.kick.kickPlayerID] ){
    return (
      <div>
      <AnimateHeight
        duration={ 500 }
        height={ openKickPlayerDropdown ? 'auto' : 0 }
        >

        <div
          style={{
            color,
            backgroundColor: 'black',
            width: 'calc(100% - 20px)',
            position: 'relative',
            left: -10,
            padding: 20,
            borderBottom: '5px solid '+color,
            boxShadow: '0 5px 10px black'
          }}
          >
          <div className='center'>
             {'Vote to kick ' + game.players[game.kick.kickPlayerID].name + '?'}
          </div>
          <div className='center'>
            {this.countVotesToKick(game.kick.votes).votesToKick +'/3 votes'}
          </div>
          <br/>
          <Row>
            <Col xs={6}>
              <Button
                color={color}
                onClick={
                  ()=>{
                    voteKickBallot(gameID, game.kick.kickPlayerID, {[player.id]: false});
                  }
                }
                >
                Ignore
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                color={color}
                onClick={
                  ()=>{
                    voteKickBallot(gameID, game.kick.kickPlayerID, {[player.id]: true});
                  }
                }
                >
                Vote
              </Button>
            </Col>
          </Row>
        </div>
      </AnimateHeight>
      </div>
    )
    }
    else{
    return (
      <div></div>
    )
    }
  }

}
