import React from 'react';
import { CirclePicker } from 'react-color';

export default class ColorPicker extends React.Component {
  changeColor(e){
    this.props.changeColor(e.hex);
  }

  render(){
    const { color } = this.props;
    return (
      <div>
        <div
          style= {{
            position: 'absolute',
            left:'50%',
            marginLeft:'-120px',
            marginTop:10
          }}
        >
          <CirclePicker
            color={ color }
            onChangeComplete={ this.changeColor.bind(this) }
            colors={[
              '#FF2020','#FF69B4','#61DAFB','#00FF00','#FFFF00','#FFA500',
              '#F43346','#FF1493','#00BFFF','#44AD44','#FFCB3B','#FF5722',
              '#C05210','#9932CC','#1E90FF','#20B2AA','#DEB887','#778899',
            ]}
          />
        </div>
        <div style={{height:130}}>
          {' '}
        </div>
      </div>
    );
  }
}
