import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import Suggestion from './ui/Suggestion';
import '../App.css';
import './ui/Error.css';
import Input from './ui/Input';
import Button from './ui/Button';
import RefreshButton from './ui/RefreshButton';
import {
  getRandomPrompt,
  submitPrompt,
  recordPromptActivity,
  startResponsePhase,
  startPromptPhase,
  lobbyUnReady
} from '../firebase';
import { CSSTransition } from 'react-transition-group';

export default class Prompt extends React.Component {

  state = {
    timesUp: false
  }

  checkForJudge(){
    const { gameID, game } = this.props;
    var players = this.props.players ? Object.keys(this.props.players) : [];
    if( players.length > 0 ){
      const noJudge = !players.includes(game.status.judge);
      if( noJudge ){
        startPromptPhase(gameID);
      }
    }
  }

  /**
    Prompt Timeout and setTimer are being hidden because they
    auto send players to lobby

    This is being replaced with a system that give players option
    to skip other plyers turns

    This may be brought back as an optional Game Setting
  **/

  /**
  promptTimeout(){
    console.log('Times Up!!!')
    const { game, gameID, player } = this.props;
    const { prompt, settings, status } = game;

    console.log('status.phase = ' + status.phase);
    if(status.phase === 'Prompt'){
      // If judge has been active,
      // then select random prompt and continue
      if( prompt.activity &&
        Number.isInteger(prompt.activity) &&
        prompt.activity > prompt.timestamp
      ){
        getRandomPrompt(prompt => {
          startResponsePhase(gameID, prompt);
        });
      }
      // If judge hasnt been active for entire round,
      // then select random prompt and send judge to lobby
      else{
        getRandomPrompt(prompt => {
          startResponsePhase(gameID, prompt);

          lobbyUnReady(gameID, {id: status.judge});
        });
      }
    }
  }

  setTimer(){
    const { prompt, settings } = this.props.game;
    if(parseInt( prompt.timestamp, 10 ) > 0){
      console.log('timestamp: ' + parseInt( prompt.timestamp, 10 ));
      console.log('maxPromptTime: ' + parseInt( settings.maxPromptTime, 10 ));
      const maxTime =
        parseInt( prompt.timestamp, 10 ) + parseInt( settings.maxPromptTime, 10 );
      console.log('maxTime: ' + maxTime);
      var timeLeft = maxTime - Date.now();
      console.log('timeLeft: ' + timeLeft);
      if(prompt.extraTime){
        timeLeft += 30000;
      }
      if(timeLeft > 0){
        this.timer = setTimeout(
          () => {
            this.setState({timesUp: true});
          },
          timeLeft
        );
      }
      else{
        this.setState({timesUp: true});
      }
    }
  }
  **/

  componentDidMount(){
    this.checkForJudge = this.checkForJudge.bind(this);
    this.checkForJudge();

    /**
    this.promptTimeout = this.promptTimeout.bind(this);
    this.setTimer = this.setTimer.bind(this);
    this.setTimer();
    **/
  }

  componentDidUpdate(prevProps, prevState){

    if(prevProps.players !== this.props.players){
      this.checkForJudge();
    }

    if(
      prevProps.game.prompt.extraTime !== this.props.game.prompt.extraTime ||
      prevProps.game.prompt.timestamp !== this.props.game.prompt.timestamp ||
      prevProps.game.settings.maxPromptTime !== this.props.game.settings.maxPromptTime
    ){
      //clearTimeout(this.timer);
      //this.setTimer();
    }

    if( prevState.timesUp !== this.state.timesUp ){
      if(this.state.timesUp){
        //this.promptTimeout();
      }
    }
  }

  render(){
    const { gameID, player, nav, game, players } = this.props;
    const isJudge = game.status.judge === player.id;
    const isPlayer = !isJudge;

    if(isJudge){
      return (
        <JudgePlaying
          gameID={gameID}
          player={player}
          nav={nav}
          game={game}
          players={players}
          />
      )
    }
    if(isPlayer){
      return (
        <PlayerWaiting
          gameID={gameID}
          player={player}
          nav={nav}
          game={game}
          players={players}
          />
      )
    }

  }
}

class PlayerWaiting extends React.Component {
  render(){
      const { game, player } = this.props;
      const players = game.players;
      const judge = game.status.judge;
      const color = player.color;

      const judgeName = players[judge] ? players[judge].name : null;

      return (
        <div>
            <Heading color={color}>Waiting for Judge</Heading>
            {judgeName &&
              <SubHeading color={color}>{judgeName + ' is the judge!'}</SubHeading>
            }
            <p>The judge is choosing a prompt</p>
        </div>
      );
  }
}

class JudgePlaying extends React.Component {
  state={
    input: '',
    error: '',
    suggestions: []
  }


  setError(error) {
    this.setState({error});
      this.timer = setTimeout(() => {
        this.setState({error: ''});
      }, 4000);
  }

  addSuggestion(suggestion){
    let array = this.state.suggestions;
    if(array.includes(suggestion)){
      return false;
    }
    array.push(suggestion);
    this.setState({suggestions: array});
    return true;
  }

  async getSuggestions(){
    await this.setState({suggestions: []});
    await getRandomPrompt(this.addSuggestion.bind(this));
    await getRandomPrompt(this.addSuggestion.bind(this));
    await getRandomPrompt(this.addSuggestion.bind(this));
  }

  async componentDidMount(){
    this.getSuggestions = this.getSuggestions.bind(this);
    await this.getSuggestions();
  }

  submit(proccessedPrompt){
    const setError = this.setError.bind(this)
    if(proccessedPrompt.error){
      setError(proccessedPrompt.error)
    }
    else{
      const prompt = proccessedPrompt.prompt;
      const { gameID, player } = this.props;
      clearTimeout(this.timer);
      submitPrompt(prompt);
      startResponsePhase(gameID, prompt);
    }
  }

  proccessPrompt(){
    const { input } = this.state;
    var prompt = input.toLowerCase();
    //First we determine if underscores were used
    var first_ = prompt.indexOf('_');
    var last_ = prompt.lastIndexOf('_');

    var usedUnderscores = first_ !== -1;
    var usedBlanks = !usedUnderscores;
    if( usedUnderscores ){

      //if underscores were used, finding multiple blanks is less intuitive

        /**
        * first we isolate part of the string:
        * starting at the first underscore, ending at the last underscore
        **/

          var underscores = prompt.substring(first_, last_);

        /**
        * then we remove the underscores from the isolated part of the string
        **/

          underscores = underscores.replaceAll('_', '').trim();

        /**
        * now if the isolated string has anything remaining,
        * we know the user had something between the first and last underscore,
        * meaning their prompt had multiple blanks
        **/

          if(underscores !== ''){
            return {error: 'Too many blanks, Please only include ONE blank'}
          }

          else{

            /**
            * now if underscores were used,
            * the word blank should not have been used
            **/

            if(prompt.includes('blank')){
              return {error: 'Too many blanks, Please only include ONE blank'}
            }

            /**
            * now that we have confirmed only one blank was used
            * we can replace the underscores with the word 'blank'
            **/
            else{
              prompt = input.replace('_', 'blank');
              prompt = prompt.replaceAll('_', '');
              return {prompt}
            }

          }

    }
    else if( usedBlanks ){

      var oneBlank =
        prompt.includes('blank') &&
        !(prompt.replace('blank', '').includes('blank'));
      var multipleBlanks = prompt.includes('blank') && !(oneBlank);
      var noBlanks = !prompt.includes('blank');

      if( oneBlank ){
        var pos1 = prompt.indexOf("blank");
        var pos2 = pos1 + 'blank'.length;

        var part1 = input.substring(0, pos1);
        var part2 = input.substring(pos2);

        prompt = part1 + 'blank' + part2;
        return {prompt}
      }
      else if ( multipleBlanks ) {
        return {error: 'Too many blanks, Please only include ONE blank'}
      }
      else if( noBlanks ) {
        var last = input.trim();
        var last = last.charAt(last.length - 1);
        var endsInQ = last === '?';
        if( endsInQ ) {
          return {prompt: input}
        }
        else{
          return {error: 'Prompt must include ONE blank or end with "?"'}
        }
      }

    }
  }

  displayPrompt(prompt){
    if(typeof prompt === 'string'){
      let blanks = prompt.replace('blank', '____________');
      return blanks;
    }
    else {
      return '';
    }
  }

  render(){
    const { suggestions, input, error } = this.state;
    const { player, gameID } = this.props;
    const { color } = player;
    const submit = this.submit.bind(this);
    const displayPrompt = this.displayPrompt.bind(this);
    const proccessPrompt = this.proccessPrompt.bind(this);
    const showError = error !== '';

    return (
      <div>

          <Heading color={color}>You Are The Judge</Heading>

          <SubHeading color={color}>Choose A Prompt </SubHeading>

          <RefreshButton
            color={color}
            onClick={() => {
              recordPromptActivity(gameID, player);
              this.getSuggestions();
            }}
          />
          {suggestions.map((sugg, k) => (
            <Suggestion
              key={k}
              selected={sugg === this.state.input}
              color={color}
              onClick={ () => {
                recordPromptActivity(gameID, player);
                this.setState({input: sugg});
              }}
            >
              {displayPrompt(sugg)}
            </Suggestion>
          ))}
          <SubHeading color={color}>Write Your Own</SubHeading>
          <Input
            multi
            placeholder='Write Your Own Prompt'
            autoFocus
            value={input}
            onChange={ (e) => {
              recordPromptActivity(gameID, player);
              this.setState({input: e.target.value});
            }}
            style={{
              fontSize: 'calc(12px + 2vmin)'
            }}
            />


          <CSSTransition
            in={showError}
            timeout={300}
            classNames="error"
            unmountOnExit
          >
            <p style={{color: 'red', fontWeight: 500}}>
              {error}
            </p>
          </CSSTransition>

          <Button
            color={color}
            onClick={ () => {
              var prompt = proccessPrompt();
              submit(prompt);
            }}>
            Continue
          </Button>

      </div>
    );
  }
}
