import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import Nametag from './ui/Nametag';
import '../App.css';
import Input from './ui/Input';
import Button from './ui/Button';
import GameIDInput from './ui/GameIDInput';
import Clipboard from './ui/Clipboard';
import {
  joinGame,
  lobbyReady,
  lobbyUnReady,
  startGame
} from '../firebase';

import { GoSettings as GameSettingsIcon } from 'react-icons/go';

export default class Lobby extends React.Component {

  onReady(){
    const { game, gameID, player } = this.props;
    const ready = game.lobby.ready ? Object.keys(game.lobby.ready) : [];
    if(ready.includes(player.id)){
      lobbyUnReady(gameID, player);
    }
    else{
      lobbyReady(gameID, player);
    }
  }

  componentDidMount(){
    const { gameID, player, nav } = this.props;

      console.log('lobby mount')
    joinGame(gameID, player, (successGameID) => {
      console.log('success in joinGame');
      if(gameID !== successGameID){
        localStorage.setItem( 'error', 'Error joining game ' + gameID);
        window.location.pathname = '/';
        nav('Home');
      }
      else{
        localStorage.setItem('joinedGame', gameID);
      }
    });
  }

  async componentDidUpdate(prevProps) {
    const { game, player, nav, gameID } = this.props;
    if (
      JSON.stringify(prevProps.game.lobby.ready) !==
      JSON.stringify(game.lobby.ready)
    ) {

      const ready = game.lobby.ready ? Object.keys(game.lobby.ready) : [];
      const players = game.players ? Object.values(game.players) : [];
      if(
        ready.length === players.length &&
        game.status.round === 0
      ){
        startGame(gameID);
      }

    }
    if ( prevProps.game.status.phase !== game.status.phase ) {

      const ready = game.lobby.ready ? Object.keys(game.lobby.ready) : [];
      if( ready.includes(player.id) ) {
        nav(game.status.phase);
      }
    }
  }

  render(){
    const { nav, gameID, game } = this.props;
    const { name, color, id } = this.props.player;
    const ready = game.lobby.ready ? Object.keys(game.lobby.ready) : [];
    const players = game.players ? Object.values(game.players) : [];
    const onReady = this.onReady.bind(this);
    return (
      <div>
        <br/>
        {//game.lobby.host === id &&
          false &&
          <Button
            outline
            color={color}
            onClick={ () => {
              nav('GameSettings');
            }}>
            <span style={{position:'relative', top:4}}>
              <GameSettingsIcon />
            </span> Game Settings
          </Button>
        }
          <Clipboard
            color={color}
            text={window.location.href}
            confirm={'Invite Link Copied To Clipboard'}
          >
            <GameIDInput
              color={color}
              value={gameID}
            />
          </Clipboard>
          <Nametag ready={ready.includes(id)} color={color} name={name} />
          <Button
            link
            color={color}
            onClick={() => {
              nav('ChangeNameColor')
            }}
            > Change Name/Color
          </Button>
          {players.map((player) => (
            <div key={player.id} >
              {player.id !== id &&
                <Nametag
                  ready={ready.includes(player.id)}
                  color={player.color}
                  name={player.name}
                  />
              }
            </div>
          ))}
          <br/>
          <Button
            color={color}
            onClick={onReady}
            disabled={players.length<3}
            >
            {ready.includes(id) ? "Oh you're ready now" : 'Ready Up'}
          </Button>

      </div>
    );
  }
}
