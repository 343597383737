import React from 'react';
import Logo from '../../img/BannerTitle.svg'

export default class LogoComp extends React.Component{
  render(){
    return (
      <div style={{
        display: 'block',
        textAlign: 'center',
        width: '100%',
      }}>
          <img
            src={Logo}
            style={{
              height: 'calc(14px + 5vmin)',
              marginTop: 'calc(-1px - 1vmin)',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            alt="!?! Explicit Idiots"
          />
      </div>
    )
  }
}
