import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import { Row, Col } from 'react-grid-system';
import '../App.css';
import Button from './ui/Suggestion';
import { updateSettings } from '../firebase';

export default class GameSettings extends React.Component {
  state = {
    settings: {
      maxPlayers: 0,
      maxPromptTime: 120000,
      maxResponseTime: 120000,
      minJudgeTime: 15000,
      maxJudgeTime: 60000,
      maxScoreboardTime: 30000
    }
  }

  alignSettings(){
    const { game } = this.props;
    if( game && game.settings ){
      this.setState({settings: game.settings});
    }
  }

  setMaxPromptTime(ms){
    var updatedSettings =
      Object.assign(
        {},
        this.state.settings,
        {maxPromptTime: ms}
      );
    this.setState({settings: updatedSettings});
  }

  setMaxResponseTime(ms){
    var updatedSettings =
      Object.assign(
        {},
        this.state.settings,
        {maxResponseTime: ms}
      );
    this.setState({settings: updatedSettings});
  }

  setMinJudgeTime(ms){
    var updatedSettings =
      Object.assign(
        {},
        this.state.settings,
        {minJudgeTime: ms}
      );
    this.setState({settings: updatedSettings});
  }

  setMaxJudgeTime(ms){
    var updatedSettings =
      Object.assign(
        {},
        this.state.settings,
        {maxJudgeTime: ms}
      );
    this.setState({settings: updatedSettings});
  }

  setMaxScoreboardTime(ms){
    var updatedSettings =
      Object.assign(
        {},
        this.state.settings,
        {maxScoreboardTime: ms}
      );
    this.setState({settings: updatedSettings});
  }

  componentDidMount(){
    const { gameID, player, nav, game } = this.props;
    this.alignSettings = this.alignSettings.bind(this);
    this.alignSettings();

    this.setMaxPromptTime = this.setMaxPromptTime.bind(this);
    this.setMaxResponseTime = this.setMaxResponseTime.bind(this);
    this.setMinJudgeTime = this.setMinJudgeTime.bind(this);
    this.setMaxJudgeTime = this.setMaxJudgeTime.bind(this);
    this.setMaxScoreboardTime = this.setMaxScoreboardTime.bind(this);
  }

  componentDidUpdate(prevProps){
    if( prevProps.game.settings !== this.props.game.settings ){
      this.alignSettings();
    }
  }

  render(){
    const { gameID, player, nav, game } = this.props;
    const { settings } = this.state;
    const {
      maxPromptTime,
      maxResponseTime,
      minJudgeTime,
      maxJudgeTime,
      maxScoreboardTime
    } = settings;
    const color = player.color;
    return (
      <div>
        <br />
          <Button
            outline
            color={color}
            onClick={
              ()=>{
                nav('Lobby')
              }
            }
            >
            Cancel
          </Button>
          <Heading color={color}>Game Settings</Heading>

          <SubHeading
            color={color}
            style={{
              fontSize: 'calc(14px + 2vmin)'
            }}
            >
            Maximum Time For Judge to Select a Prompt
          </SubHeading>
          <Row>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxPromptTime === 120000}
                onClick={()=>{
                  this.setMaxPromptTime(120000);
                }}
                >
                2 min
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxPromptTime === 180000}
                onClick={()=>{
                  this.setMaxPromptTime(180000);
                }}
                >
                3 min
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxPromptTime === 300000}
                onClick={()=>{
                  this.setMaxPromptTime(300000);
                }}
                >
                5 min
              </Button>
            </Col>
          </Row>

          <SubHeading
            color={color}
            style={{
              fontSize: 'calc(14px + 2vmin)'
            }}
            >
            Maximum Time For a Player to Choose a Response
          </SubHeading>
          <Row>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxResponseTime === 120000}
                onClick={()=>{
                  this.setMaxResponseTime(120000);
                }}
                >
                2 min
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxResponseTime === 180000}
                onClick={()=>{
                  this.setMaxResponseTime(180000);
                }}
                >
                3 min
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxResponseTime === 300000}
                onClick={()=>{
                  this.setMaxResponseTime(300000);
                }}
                >
                5 min
              </Button>
            </Col>
          </Row>

          <SubHeading
            color={color}
            style={{
              fontSize: 'calc(14px + 2vmin)'
            }}
            >
            Minimum Time For Players to Read the Responses Before a Winner is Chosen
          </SubHeading>
          <Row>
            <Col xs={4}>
              <Button
                color={color}
                selected={minJudgeTime === 15000}
                onClick={()=>{
                  this.setMinJudgeTime(15000);
                }}
                >
                15 sec
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={minJudgeTime === 30000}
                onClick={()=>{
                  this.setMinJudgeTime(30000);
                }}
                >
                30 sec
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={minJudgeTime === 60000}
                onClick={()=>{
                  this.setMinJudgeTime(60000);
                }}
                >
                1 min
              </Button>
            </Col>
          </Row>

          <SubHeading
            color={color}
            style={{
              fontSize: 'calc(14px + 2vmin)'
            }}
            >
            Maximum Time For Judge to Choose a Winner
          </SubHeading>
          <Row>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxJudgeTime === 60000}
                onClick={()=>{
                  this.setMaxJudgeTime(60000);
                }}
                >
                1 min
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxJudgeTime === 90000}
                onClick={()=>{
                  this.setMaxJudgeTime(90000);
                }}
                >
                90 sec
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxJudgeTime === 120000}
                onClick={()=>{
                  this.setMaxJudgeTime(120000);
                }}
                >
                2 min
              </Button>
            </Col>
          </Row>

          <SubHeading
            color={color}
            style={{
              fontSize: 'calc(14px + 2vmin)'
            }}
            >
            Maximum Time For Players To Review Round and View Scoreboard
          </SubHeading>
          <Row>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxScoreboardTime === 30000}
                onClick={()=>{
                  this.setMaxScoreboardTime(30000);
                }}
                >
                30 sec
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxScoreboardTime === 60000}
                onClick={()=>{
                  this.setMaxScoreboardTime(60000);
                }}
                >
                1 min
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={color}
                selected={maxScoreboardTime === 120000}
                onClick={()=>{
                  this.setMaxScoreboardTime(120000);
                }}
                >
                2 min
              </Button>
            </Col>
          </Row>

          <Button style={{marginTop:5}} color={color} onClick={ () => {
              updateSettings(gameID, player, settings);
              nav('Lobby');
            }}> Save Settings </Button>

        </div>
    );
  }
}
