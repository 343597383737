import React from 'react';
import '../../App.css';
import { Row, Col } from 'react-grid-system';

export default function Suggestion(props){
  const back = '#313131';
  const thisStyle = props.selected ?
  {
    border: `2px solid ${props.color}`,
    borderRadius: '5px',
    backgroundColor: props.color,
    color: back,
    margin: '7px',
    padding: '5px',
    cursor: 'pointer'
  } :
  {
    border: `2px solid ${props.color}`,
    borderRadius: '5px',
    backgroundColor: `${props.color}10`,
    color: props.color,
    margin: '7px',
    padding: '5px',
    cursor: 'pointer'
  };
  return (
    <Row>
      <Col xs={12} className='center'>
        <div
          style={
            Object.assign({}, thisStyle, props.style)
          }
          onClick={props.onClick}>
          <span styles={{color: props.color}}>{props.children}</span>
        </div>
      </Col>
    </Row>
  );
}
