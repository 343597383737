import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import ColorPicker from './ui/ColorPicker';
import '../App.css';
import Input from './ui/Input';
import Button from './ui/Button';
import { reportIssue } from '../firebase';

export default class ReportIssue extends React.Component {
  state = {
    subject: '',
    description: ''
  }

  render(){
    const { color, onContinue, meta } = this.props;
    const { subject, description } = this.state;
    return (
      <div>
        <br />
          <Button
            outline
            color={color}
            onClick={onContinue}
            >
            Cancel
          </Button>
          <Heading color={color}>Report An Issue</Heading>
          <SubHeading color={color}>Subject</SubHeading>
          <Input
            placeholder='Subject'
            value={subject}
            onChange={ (e) => {
              this.setState({subject: e.target.value});
            }}/>
          <SubHeading color={color}>Description</SubHeading>
          <Input
            multi
            placeholder='Describe what the issue was and how it occurred'
            value={description}
            onChange={ (e) => {
              this.setState({description: e.target.value});
            }}/>
          <Button style={{marginTop:5}} color={color} onClick={ () => {
              reportIssue({subject, description, meta});
              onContinue();
            }}> Send Report </Button>

        </div>
    );
  }
}
