import React from 'react';
import '../../App.css';
import { Button, Anchor } from '@zendeskgarden/react-buttons';

export default function NewButton(props){
    const { color, onClick, outline, link, disabled, style, inline, shareIcon } = props;
    let enabled = disabled ? `${color}70` : color
    return (
      <div>
        {!outline && !link &&
          <Button
            disabled={disabled}
            isPrimary
            isStretched={!inline}
            style={Object.assign(
                {
                  background: enabled,
                  color: '#313131',
                  fontSize: 'calc(8px + 2vmin)',
                  height: 'calc(30px + 2.5vmin)',
                },
                style
            )}
            onClick={onClick}
            >
            {props.children}
          </Button>
        }
        {outline && !link &&
          <Button
            disabled={disabled}
            isStretched={!inline}
            style={Object.assign(
              {
                borderColor: enabled,
                color: enabled,
                fontSize: 'calc(8px + 2vmin)',
                height: 'calc(30px + 2.5vmin)',
              },
              style
            )}
            onClick={onClick}
            >
            {props.children}
          </Button>
        }
        {link &&
          <Anchor
            isExternal={shareIcon}
            style={Object.assign(
              {
                color,
                display: 'block',
                margin: 0,
                position: 'relative',
                textAlign: 'center',
                padding: 0
              },
              style
            )}
            onClick={onClick}
            >
            {props.children}
          </Anchor>
        }
      </div>
    );
}
