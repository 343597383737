import React from 'react';
import Suggestion from './Suggestion';

export default function Prompt(props){
  return (
    <Suggestion
      selected={true}
      color={props.color}
      onClick={() => {}}
      style={{cursor: 'default'}}
      >
      {display(props.prompt)}
    </Suggestion>
  );
}


  function display(prompt){
    console.log('PROMPT');
    console.log(prompt);
    if(typeof prompt === 'string'){
      let blanks = prompt.replace('blank', '____________');
      return blanks;
    }
    else {
      return '';
    }
  }
