import React from 'react';
import './Clipboard.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CSSTransition } from 'react-transition-group';

export default class Clipboard extends React.Component {
  state = {copied: false}
  setCopied() {
    this.setState({copied: true});
      this.timer = setTimeout(() => {
        this.setState({copied: false});
      }, 3000);
  }
  render(){
    const { color, text, confirm, children } = this.props;
    const { copied } = this.state;
    let copy = this.setCopied.bind(this);
    return(
      <div>
      <CopyToClipboard
        text={text}
        onCopy = {()=>{
          copy();
        }}
        style={{display:'inline-block'}}
        >
        <span>
          {children}
        </span>

      </CopyToClipboard>
      <CSSTransition
        in={copied}
        timeout={500}
        classNames="alert"
        unmountOnExit
      >
      <div
        style={{
          color: '#f0fff0',
          textAlign: 'center'
        }}
        >
        {'  ' + confirm}
      </div>
      </CSSTransition>
    </div>

    )
  }
}
