import * as firebase from 'firebase/app';
import 'firebase/database';
import uid from 'uid';
import arrayShuffle from 'array-shuffle';

const firebaseConfig = {
  apiKey: "AIzaSyD6fhRG-PbIUIdkf19I-r6ckx4u591ZPd0",
  authDomain: "explicitidiots.firebaseapp.com",
  databaseURL: "https://explicitidiots.firebaseio.com",
  projectId: "explicitidiots",
  storageBucket: "explicitidiots.appspot.com",
  messagingSenderId: "548631112337",
  appId: "1:548631112337:web:e324d7bf74ef3338c12d30"
};
firebase.initializeApp(firebaseConfig);
const db = firebase.database();

export async function reportIssue(issue){
    var id = await db.ref().child('issues').push().key;
    const hasSubject = issue.subject != null && issue.subject != '';
    const hasDescription = issue.description != null && issue.description != '';
    if( hasSubject || hasDescription ){
      await db.ref('issues/' + id).set(issue);
    }
}

export async function setGameListener(gameID, listener, notFound){
  if(gameID && gameID !== ''){
    await db.ref('games/' + gameID).on('value', (e) => {
      if(e.exists()){
        listener(e.val());
      }
      else{
        notFound();
      }
    });
  }
}

export async function getRandomPrompt(setter){
  var randomID = uid(10);
  var childName = 'random' + (Math.floor(Math.random() * 3) + 1);
  await db.ref('prompts/').orderByChild(childName)
    .startAt(randomID).limitToFirst(1)
    .once('value', (e) => {
      if(e.exists()){
        let prompt = Object.values(e.val())[0].text
        if(setter(prompt) === false){
          this ? this.getRandomPrompt(setter) : getRandomPrompt(setter)
        }
      }
      else{
        this ? this.getRandomPrompt(setter) : getRandomPrompt(setter)
      }
    })
}

export async function submitPrompt(input){
  var random1 = uid(10);
  var random2 = uid(10);
  var random3 = uid(10);
  var id = db.ref().child('prompts').push().key;

  var promptData = {
    random1, random2, random3, text: input
  };
  await db.ref('prompts/' + id).set(promptData, (err) => {
    if(err){
      console.log('error occurred while submitting prompt:\n' + JSON.stringify(err));
    }
    else{
      console.log('successfully submitted prompt:\n"' + input + '"');
    }
  })
}

export async function getRandomResponse(setter){
  var randomID = uid(14);
  var childName = 'random' + (Math.floor(Math.random() * 3) + 1);
  await db.ref('responses/').orderByChild(childName)
    .startAt(randomID).limitToFirst(1)
    .once('value', (e) => {
      if(e.exists()){
        let response = Object.values(e.val())[0].text
        if(setter(response) === false){
          this ? this.getRandomResponse(setter) : getRandomResponse(setter)
        }
      }
      else{
        this ? this.getRandomResponse(setter) : getRandomResponse(setter)
      }
      //let randomPrompt = e.val().text;
      //return randomPrompt
    })
}

export async function submitResponseKey(){
  var id = await db.ref().child('responses').push().key;
  return id;
}

export async function submitResponse(key, input){
  var random1 = uid(14);
  var random2 = uid(14);
  var random3 = uid(14);

  var responseData = {
    random1, random2, random3, text: input
  };
  await db.ref('responses/' + key).set(responseData)
}

export async function hostGame(player, success){
  var callback = success ? success : ()=>{};
  var gameID = uid(4).toUpperCase();
  console.log(gameID);
  await db.ref('games/' + gameID).once("value", async (snapshot) => {
    if (snapshot.exists()){
      const game = snapshot.val();
      //game is valid if
      //it was made within last 6 hours and has at least one player
      const sixHours = 1000 * 60 * 60 * 6;
      const sixHoursAgo = Date.now() - sixHours;
      const sixHoursOld =
        game.timestamp ?
        game.timestamp < sixHoursAgo :
        true;
      const hasPlayers =
        game.players ?
        Object.keys(game.players).length > 0 :
        false;
      const gameIsValid = !sixHoursOld && hasPlayers;

      if( gameIsValid ){
        var recursive = this ? this.hostGame : hostGame;
        return recursive(player, callback);
      }
      else{
        return await createGame();
      }
    }
    else{
      return await createGame();
    }
  });
  async function createGame(){

    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    var dateCreated = mm + '/' + dd + '/' + yyyy;
    var timestamp = Date.now();

    await db.ref('games/' + gameID).set({
      dateCreated,
      timestamp,
      settings: {
        maxPlayers: 0,
        maxPromptTime: 120000,
        maxResponseTime: 120000,
        minJudgeTime: 15000,
        maxJudgeTime: 60000,
        maxScoreboardTime: 30000
      },
      status: {
        phase: 'Lobby',
        round: 0,
        judge: '',
        judgeQueue: {
          0: player.id
        },
      },
      players: {
        [player.id]: {
          id: player.id,
          name: player.name,
          color: player.color
        }
      },
      lobby: {
        host: player.id,
        ready: {}
      },
      prompt: {
        round: 0,
        ready: '',
        prompt: '',
        timestamp: '',
        extraTime: false,
      },
      response: {
        round: 0,
        submissions: {},
        activity: {},
        timestamp: ''
      },
      judge: {
        round: 0,
        favorites: {},
        winner: '',
        timestamp: ''
      },
      scoreboard: {
        round: 0,
        score: {
          [player.id]: {
            wins: 0,
            favs: 0
          }
        },
        ready: {},
        timestamp: ''
      }
    }).then(async () => {
      console.log(gameID)
      return callback(gameID);
    }).catch((err) => {
      console.log(err)
      return callback('');
    });
  }
}

export async function joinGame(gameID, player, success){

    var callback = success ? success : ()=>{};

  await db.ref('games/' + gameID).once("value", async (snapshot) => {
    console.log('snapshot')
    if (snapshot.exists()){

        console.log('PLAYER joining')
      const game = snapshot.val();
      //game is valid if
      //it was made within last 6 hours and has at least one player
      const sixHours = 1000 * 60 * 60 * 6;
      const sixHoursAgo = Date.now() - sixHours;
      const sixHoursOld = game.timestamp < sixHoursAgo;
      const hasPlayers =
        game.players ?
        Object.keys(game.players).length > 0 :
        false;
      const gameIsValid = !sixHoursOld && hasPlayers;

      if( !gameIsValid ){
        return callback('')
      }

      const leaving = game.leaving ? Object.keys(game.leaving) : [];

      console.log(JSON.stringify(leaving))
      //if the joined game in localStorage does not match the gameID,
      //the player must have successfully left the game at some point.
      const joinedGame = localStorage.getItem('joinedGame');
      const playerIsLeaving =
        leaving.includes(player.id) && joinedGame === gameID;

      if( playerIsLeaving ){
        console.log('PLAYER LEAVING')
        return callback('');
      }
      else{

        const judgeQueue = game.status.judgeQueue ? game.status.judgeQueue : {};
        const order = Object.keys(judgeQueue);
        const judgeArray = Object.values(judgeQueue);
        const scoreArray = game.scoreboard.score ?
          Object.keys(game.scoreboard.score):
          [];
        const nextIndex =
          parseInt(order[order.length-1], 10)
          + 1;
        var updates = {};
        updates['leaving/' + player.id] = null;
        updates['players/' + player.id] = {
          id: player.id,
          name: player.name,
          color: player.color
        };
        if( !scoreArray.includes(player.id) ){
          updates['scoreboard/score/' + player.id] = {
            wins: 0,
            favs: 0
          };
        }
        if( !judgeArray.includes(player.id) ){
          updates['status/judgeQueue/' + nextIndex] = player.id;
        }

        await db.ref('games/' + gameID).update(updates, (err) => {
          if(err) {
            return callback('')
          }
          else{
            return callback(gameID)
          }
        });
      }
    }
    else{
      return callback('')
    }
  });
}

export async function leaveGame(gameID, player, success){
  var callback = success ? success : ()=>{};
  await db.ref('games/' + gameID).once("value", async (snapshot) => {
    if (snapshot.exists()){
      const game = snapshot.val();
      if( game.players && Object.keys(game.players).includes(player.id) ){
        const status = game.status;
        const isJudge = player.id === status.judge;
        const isHost = player.id === game.lobby.host;
        console.log('HOST')

        var updates = {};
        updates['players/' + player.id] = null;
        updates['scoreboard/score/' + player.id] = null;
        updates['lobby/ready/' + player.id] = null;
        updates['leaving/' + player.id] = true;

        if( status.judgeQueue ){
          var index = Object.values(status.judgeQueue).indexOf(player.id);
          const judgeIndex = Object.keys(status.judgeQueue)[index];
          updates['status/judgeQueue/' + judgeIndex] = null;

          if( isJudge ){
            this ?
            await this.startPromptPhase(gameID) :
            await startPromptPhase(gameID);
          }
          if( isHost ){
            const nextJudgeIndex =
              index + 1 === Object.keys(status.judgeQueue).length ?
              0 :
              index + 1;
            const nextHost = Object.values(status.judgeQueue)[nextJudgeIndex];
            updates['lobby/host'] = nextHost;
          }
        }

        await db.ref('games/' + gameID).update(updates, (err) => {
          if(err) {
            return callback('')
          }
          else{
            return callback(gameID)
          }
        });
      }
    }
    else{
      return callback('')
    }
  });
}

export async function confirmLeaveGame(gameID, player, success){

  var callback = success ? success : ()=>{};
  await db.ref('games/' + gameID + '/status').once("value", async (snapshot) => {
    if (snapshot.exists()){
      const status = snapshot.val();

      var updates = {};
      updates['players/' + player.id] = null;
      updates['scoreboard/score/' + player.id] = null;
      updates['lobby/ready/' + player.id] = null;
      updates['leaving/' + player.id] = null;

      if( status.judgeQueue ){
        var index = Object.values(status.judgeQueue).indexOf(player.id);
        const judgeIndex = Object.keys(status.judgeQueue)[index];
        updates['status/judgeQueue/' + judgeIndex] = null;
      }

      await db.ref('games/' + gameID).update(updates, (err) => {
        if(err) {
          return callback('')
        }
        else{
          return callback(gameID)
        }
      });
    }
    else{
      return callback('')
    }
  });
}

export async function updateSettings(gameID, player, settings){

  await db.ref('games/' + gameID).once("value", async (snapshot) => {
    if (snapshot.exists()){
      const game = snapshot.val();
      if( game.lobby.host === player.id ){
        await db.ref('games/' + gameID + '/settings').set(settings, (err) => {
          if(err) {
            return console.log('error on changing settings')
          }
          else{
            return console.log('success changing settings')
          }
        });
      }
    }
  });
}

export async function lobbyReady(gameID, player){
    await db.ref('games/' + gameID + '/lobby/ready/' + player.id).set(true);
}

export async function lobbyUnReady(gameID, player){
    await db.ref('games/' + gameID + '/lobby/ready/' + player.id).set(null);
}

export async function resetKickVote(gameID){
    await db.ref('games/' + gameID + '/kick').set(null);
}

export async function voteKickBallot(gameID, kickID, ballot){

    await db.ref('games/' + gameID).once("value", async (snapshot) => {
      console.log(' way2go');
      console.log(JSON.stringify(snapshot.val()));

      if(snapshot.exists()){
        const game = snapshot.val();
        console.log(' way2go 0');
        if( game.kick && game.players ){
          const timeLimit = 20000;
          const timeLeft = timeLimit + game.kick.timestamp - Date.now();
          const expired = timeLeft <= 0;
          console.log(' way2go 1');
          if(
            Object.keys(game.players).includes(game.kick.kickPlayerID) &&
            game.kick.kickPlayerID === kickID &&
            !expired
          ){
            console.log(' way2go 2');
            const votes = Object.assign({}, game.kick.votes, ballot);
            await db.ref('games/' + gameID + '/kick/votes').set(votes);
          }
        }

      }
    });
}

export async function voteToKick(gameID, player, kickID){
  await db.ref('games/' + gameID).once("value", async (snapshot) => {
    if(snapshot.exists()){

      const kick = {
        timestamp: Date.now(),
        kickPlayerID: kickID,
        votes:{
          [player.id]: true
        }
      }

      console.log(JSON.stringify(kick));
      const game = snapshot.val();

      console.log( game.lobby.host + ' === ' + player.id + '\n&&\n' + game.status.round)
      if( game.lobby.host === player.id && game.status.round === 0 ){
        return this ?
          this.leaveGame(gameID, {id: kickID}) :
          leaveGame(gameID, {id: kickID});
      }

      if( game.players ){
        if( game.kick ){
          const timeLimit = 20000;
          const timeLeft = timeLimit + game.kick.timestamp - Date.now();
          console.log(timeLeft);
          const expired = timeLeft <= 0;

          if( expired ){
            return await db.ref('games/' + gameID + '/kick').set(kick);
          }
          else{
            console.log('not voted');
            return { error: 'Please wait for current vote to finish' }
          }
        }
        else{
          return await db.ref('games/' + gameID + '/kick').set(kick);

            console.log('voted');
        }

      }

    }
  });
}

export async function startGame(gameID){
  await db.ref('games/' + gameID + '/players').once("value", async (snapshot) => {
    const players = snapshot.val();
    const judgeQueue = arrayShuffle(Object.keys(players));
    var updates = {};
    updates['/prompt'] = {
      round: 1,
      ready: '',
      prompt: '',
      extraTime: false,
      timestamp: Date.now()
    };
    updates['/status'] = {
      phase: 'Prompt',
      round: 1,
      judge: judgeQueue[0],
      judgeQueue
    };
    updates['/response/round'] = 0;
    updates['/judge/round'] = 0;
    updates['/scoreboard/round'] = 0;

    await db.ref('games/' + gameID).update(updates);
  });
}

export async function resetGame(gameID){
  await db.ref('games/' + gameID).once("value", async (snapshot) => {
    if( snapshot.exists() ){
      const game = snapshot.val();

      if(
        game.players &&
        Object.keys(game.players).length < 3 &&
        game.status.round !== 0
      ){

        var updates = {};
        updates['/status/phase'] = 'Lobby';
        updates['/status/judge'] = '';
        updates['/status/round'] = 0;
        updates['/prompt/round'] = 0;
        updates['/response/round'] = 0;
        updates['/judge/round'] = 0;
        updates['/lobby/ready'] = null;
        updates['/scoreboard/round'] = 0;

        await db.ref('games/' + gameID).update(updates);
      }
    }
  });
}

export async function recordPromptActivity(gameID, player){
  console.log('ACTIVITY!!!')
  await db.ref('games/' + gameID).once("value", async (snapshot) => {
    if (snapshot.exists()){
      const game = snapshot.val();
      const { status, settings, prompt } = game;
      console.log(JSON.stringify(status));
      if( status.judge === player.id ){

        const maxTime =
          parseInt( prompt.timestamp, 10 ) + parseInt( settings.maxPromptTime, 10 );
        const timeLeft = maxTime - Date.now();
        if( timeLeft > 0 ){
          var timestamp = Date.now();

          var updates = {};
          updates['/activity'] = timestamp;
          if(timeLeft < 15000){
            updates['/extraTime'] = true;
          }
          await db.ref('games/' + gameID + '/prompt').update(updates);
        }
      }
    }
  });
}

export async function startResponsePhase(gameID, prompt){
  await db.ref('games/' + gameID + '/status').once("value", async (snapshot) => {
    if (snapshot.exists()){
      var status = snapshot.val();
      console.log(JSON.stringify(status));
        var updates = {};
        updates['/prompt/ready'] = status.judge;
        updates['/prompt/prompt'] = prompt;
        updates['/status/phase'] = 'Response';
        updates['/response/submissions'] = {};
        updates['/response/activity'] = {};
        updates['/response/timestamp'] = Date.now();
        updates['/response/round'] = status.round;
        await db.ref('games/' + gameID).update(updates, (err) => {
          if(err){
            console.log('error occurred while starting response phase:\n' + JSON.stringify(err));
          }
          else{
            console.log('successfully completed update');
          }
        });
    }
    else{
      console.log('snapshot doesn\'t exist');
    }
  });
}

export async function recordResponseActivity(gameID, player){
  console.log('ACTIVITY!!!')
  await db.ref('games/' + gameID).once("value", async (snapshot) => {
    if (snapshot.exists()){
      const game = snapshot.val();
      const { status, settings, response } = game;
      console.log(JSON.stringify(status));
      if( status.judge !== player.id ){

        const maxTime =
          parseInt( response.timestamp, 10 ) + parseInt( settings.maxResponseTime, 10 );
        const timeLeft = maxTime - Date.now();
        if( timeLeft > 0 ){
          var timestamp = Date.now();
          await db.ref('games/' + gameID + '/prompt/activity/' + player.id).set(timestamp);
        }
      }
    }
  });
}

export async function responseActivity(gameID, player){
  await db.ref('games/' + gameID + '/status').once("value", async (snapshot) => {
    if (snapshot.exists()){
      var status = snapshot.val();
      console.log(JSON.stringify(status));
      if( status.judge !== player.id ){
        var timestamp = Date.now();
        await db.ref('games/' + gameID + '/response/activity/' + player.id).set(timestamp);
      }
    }
  });
}

export async function stageResponse(gameID, player, response){
    await db.ref('games/' + gameID + '/response/submissions/' + player.id).set(response);
}

export async function undoResponse(gameID, player){
    await db.ref('games/' + gameID + '/response/submissions/' + player.id).set(null);
}

export async function startJudgePhase(gameID){
  await db.ref('games/' + gameID + '/status').once("value", async (snapshot) => {
    if (snapshot.exists()){
      var status = snapshot.val();
        var updates = {};
        updates['/status/phase'] = 'Judge';
        updates['/judge/round'] = status.round;
        updates['/judge/favorites'] = {};
        updates['/judge/winner'] = '';
        updates['/judge/timestamp'] = Date.now();
        await db.ref('games/' + gameID).update(updates);
    }
  });
}

export async function favoriteResponse(gameID, player, responseID){
  if(player.id !== responseID){
    await db.ref(
      'games/' + gameID + '/judge/favorites/' + player.id
    ).set(responseID);
  }
}

export async function undoFavoriteResponse(gameID, player, responseID){
  if(player.id !== responseID){
    await db.ref(
      'games/' + gameID + '/judge/favorites/' + player.id
    ).set(null);
  }
}

export async function winningResponse(gameID, player, responseID){
  if(player.id !== responseID){
    await db.ref('games/' + gameID + '/status/judge').once("value", async (snapshot) => {
      if (snapshot.exists()){
        const judgeID = snapshot.val();
        if(player.id === judgeID){
          await db.ref('games/' + gameID + '/judge/winner').set(responseID);
        }
      }
    });
  }
}

export async function undoWinningResponse(gameID, player, responseID){
  if(player.id !== responseID){
    await db.ref('games/' + gameID + '/status/judge').once("value", async (snapshot) => {
      if (snapshot.exists()){
        const judgeID = snapshot.val();
        if(player.id === judgeID){
          await db.ref('games/' + gameID + '/judge/winner').set(null);
        }
      }
    });
  }
}

export async function startScoreboardPhase(gameID){
  await db.ref('games/' + gameID).once("value", async (snapshot) => {
    if (snapshot.exists()){
      const game = snapshot.val();
      const { scoreboard, status, judge } = game;
      const { winner, favorites } = judge;
      const { score } = scoreboard;
        var updates = {};
        updates['/status/phase'] = 'Scoreboard';
        updates['/scoreboard/ready'] = {};
        updates['/scoreboard/round'] = status.round;
        updates['/scoreboard/timestamp'] = Date.now();

        var newScore = Object.assign({}, score);
        if( judge.round === status.round ){
          if( favorites ){
            Object.values(favorites).forEach(favoritedID => {
              if(!newScore[favoritedID])
                newScore[favoritedID] = {favs: 0, wins: 0};
              newScore[favoritedID].favs = newScore[favoritedID].favs + 1;
            })
          }
          if( winner && winner !== ''){
            newScore[winner].wins = newScore[winner].wins + 1;
          }
        }

        updates['/scoreboard/score'] = newScore;
        await db.ref('games/' + gameID).update(updates);
    }
  });
}

export async function scoreboardReady(gameID, player){
    await db.ref('games/' + gameID + '/scoreboard/ready/' + player.id).set(true);
}

export async function startPromptPhase(gameID){

    await db.ref('games/' + gameID).once("value", async (snapshot) => {

      if (snapshot.exists()){

        const game = snapshot.val();
        const { status, lobby } = game;
        const ready = lobby.ready ? Object.keys(lobby.ready) : [];

        if(ready.length > 0){
          function determineNextJudge(currentJudgeID){
            var judgeQueueKeys = Object.keys(status.judgeQueue);
            var judgeQueueValues = Object.values(status.judgeQueue);
            var nextJudgeIndex = judgeQueueValues.indexOf(currentJudgeID) + 1;
            if(nextJudgeIndex === judgeQueueValues.length) nextJudgeIndex = 0;
            var nextJudgeKey = judgeQueueKeys[nextJudgeIndex];
            var nextJudgeID = status.judgeQueue[nextJudgeKey];
            if(ready.includes(nextJudgeID)){
              return nextJudgeID;
            }

            else{
              //alert('skipping ' + nextJudgeID);
              return determineNextJudge(nextJudgeID);
            }
          }

          const nextJudgeID = determineNextJudge(status.judge);
          const nextRound = parseInt(status.round, 10) + 1;
          var updates = {};
          updates['/prompt/round'] = nextRound;
          updates['/prompt/ready'] = '';
          updates['/prompt/prompt'] = '';
          updates['/prompt/extraTime'] = false;
          updates['/prompt/timestamp'] = Date.now();
          updates['/status/phase'] = 'Prompt';
          updates['/status/round'] = nextRound;
          updates['/status/judge'] = nextJudgeID;
          await db.ref('games/' + gameID).update(updates);
        }
      }

    });

}
