import React from 'react';
import WebContainer from './web/ui/HeaderAndContainer';
import Home from './web/Home';
import Game from './web/Game';
import ChangeNameColor from './web/ChangeNameColor';
import Help from './web/Help';
import ReportIssue from './web/ReportIssue';
import uid from 'uid';
import { confirmLeaveGame } from './firebase.js';

class App extends React.Component {
  state={
    page: 'Home',
    color: '#61dafb',
    name: '',
    id: '',
    gameID: '',
    pageNames: [
      'Home',
      'ChangeNameColor',
      'ReportIssue',
      'Help'
    ]
  }

  //state.page linked to local
  async changePage(page){
    localStorage.setItem( 'page', page );
    await this.setState({page});
    console.log('page: ' + page);
  }

  //state.color linked to local
  changeColor(color){
    localStorage.setItem( 'color', color );
    this.setState({color});
  }

  //state.name linked to local
  setName(name){
    localStorage.setItem( 'name', name );
    this.setState({name});
  }

  async checkVersion(){
    const cachedVersion = localStorage.getItem( 'v' );
    console.log('v=' + cachedVersion);

    if( cachedVersion !== '0.2.1' ){
      localStorage.clear();
      localStorage.setItem( 'v', '0.2.1' );
      //await this.changePage('Help');
    }
  }

  //retrieve localStorage
  async persistState(){
    let page = localStorage.getItem( 'page' ) || this.state.page;
    let color = localStorage.getItem( 'color' ) || this.state.color;
    let name = localStorage.getItem( 'name' ) || this.state.name;
    let id = localStorage.getItem( 'id' ) || this.state.id;

    let state = { page, color, name, id }

    await this.setState(state);
  }

  //initialization
  async componentDidMount(){
    this.changePage = this.changePage.bind(this);
    this.presistState = this.persistState.bind(this);
    this.checkVersion = this.checkVersion.bind(this);
    //recover data
    await this.persistState();
    await this.checkVersion();

    console.log('persisted:\n' + JSON.stringify(this.state));

    //set gameid based on pathname
    var gameID = window.location.pathname.substr(1, 4).toUpperCase();
    this.setState({gameID});
    console.log('GAMEID: ' + gameID);
    //if pathname doesnt exist, confirm leave game
    const joinedGame = localStorage.getItem('joinedGame');

    console.log('joinedGame: ' + joinedGame);
    const player = {id: this.state.id};
    if(
      gameID === '' &&
      joinedGame &&
      joinedGame !== '' &&
      player.id &&
      player.id !== ''
    ){

        confirmLeaveGame(joinedGame, player, successID => {
          if( successID === joinedGame){
            localStorage.removeItem('joinedGame');
          }
        });
    }

    //set player.id if it doesnt exist
    if(this.state.id === ''){
      var id = uid();
      localStorage.setItem( 'id', id );
      this.setState({id});
    }
    //send user to choose name and color
    console.log(this.state.page);
    if( this.state.name == '' && this.state.page !== 'Help' ) {
      console.log('missing name');
      await this.changePage('ChangeNameColor');
    }

    //if not in game and page does not exist, send to home
    if( gameID == null || gameID === '' ){
      if(!this.state.pageNames.includes(this.state.page)){
        this.changePage('Home');
      }
    }

  }

  async componentDidUpdate(prevProps, prevState) {
    if ( prevState.page !== this.state.page || prevState.name !== this.state.name ) {
      if( this.state.name == '' && this.state.page !== 'Help' ) {
        console.log('missing name');
        this.changePage('ChangeNameColor');
      }
    }
    if( prevState.page !== this.state.page || prevState.gameID !== this.state.gameID ){
      //if not in game and page does not exist, send to home
      if( this.state.gameID == null || this.state.gameID === '' ){
        if(!this.state.pageNames.includes(this.state.page)){
          this.changePage('Home');
        }
      }
    }
  }

  render(){
    const { page, color, gameID, name, id } = this.state;
    const player = {id, color, name};

    const nav = this.changePage.bind(this);
    const changeColor = this.changeColor.bind(this);
    const setName = this.setName.bind(this);

    return (
      <WebContainer color={color} nav={nav} state={this.state}>
        {gameID === '' &&
          <div>
            {page === 'Home' &&
                <Home
                  color={color}
                  nav={nav}
                  state={this.state}
                  />
            }
            {page === 'ChangeNameColor' &&
                <ChangeNameColor
                  onContinue={() => {
                    nav('Home');
                  }}
                  color={color}
                  setName={setName}
                  changeColor={changeColor}
                  name={name}
                  />
            }
            {page === 'ReportIssue' &&
                <ReportIssue
                  onContinue={() => {
                    nav('Home');
                  }}
                  color={color}
                  meta={this.state}
                  />
            }
            {page === 'Help' &&
                <Help
                  onContinue={() => {
                    nav('Home');
                  }}
                  color={color}
                  />
            }
          </div>
        }
        {gameID !== '' &&
            <Game
              state={this.state}
              gameID={gameID}
              color={color}
              player={player}
              page={page}
              nav={nav}
              setName={setName}
              changeColor={changeColor}
              />
        }
      </WebContainer>
    )
  }
}

export default App;
