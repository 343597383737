import React from 'react';
import '../../App.css';
import { Row, Col } from 'react-grid-system';

export function Heading(props){
  return (
    <Row>
      <Col xs={12} className='center'>
        <h2
          style={
            Object.assign(
              {
                color: props.color,
                marginBottom: 0,
                cursor: 'default'
              },
              props.style
            )
          }>
          {props.children}
        </h2>
      </Col>
    </Row>
  );
}

export function SubHeading(props){
  return (
    <Row>
      <Col xs={12}>
        <h3
          style={
            Object.assign(
              {
                fontWeight: 500,
                color: props.color,
                marginBottom: 0,
                cursor: 'default'
              },
              props.style
            )
          }>
          {props.children}
        </h3>
      </Col>
    </Row>
  );
}
