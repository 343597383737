import React from 'react';
import '../../App.css';
import { Container, Row, Col, Visible } from 'react-grid-system';
import Logo from './Logo'
import SettingsMenu from './SettingsMenu'
import { AiTwotoneSetting, AiOutlineSetting } from 'react-icons/ai'

export default class WebContainer extends React.Component{
  render(){
    const {children, color, state, nav} = this.props;
    console.log('props' + color)

    return (
      <div className='background'>
        <Container className='App'
          style={{
            paddingLeft: 0,
            paddingRight: 0
          }}>
          <Row>
            <Visible xs sm md>
              <Col xs={12} >
                <Header color={color} state={state} nav={nav} />
                <div className='body'>
                  {children}
                </div>
              </Col>
            </Visible>
            <Visible lg xl>
              <Col xs={2} />
              <Col xs={8} >
                <Header color={color} state={state} nav={nav} />
                <div className='body'>
                  {children}
                </div>
              </Col>
            </Visible>
          </Row>
          <br />
          <br />
        </Container>
    </div>
    )
  }
}

class Header extends React.Component{
  state = {
    openSettings: false
  }

  toggleSettings(){
    let openSettings = this.state.openSettings
    this.setState({openSettings: !openSettings });
  }

  render(){
    const {children, color, nav, state} = this.props;
    const {openSettings} = this.state;
    const toggleSettings = this.toggleSettings.bind(this);
    return (
      <div>
          <div id='Heading'
            style={{
              backgroundColor: color,
            }}>
            <Row>
              <Col xs={2} />
              <Col xs={8} >
                <Logo />
              </Col>
              <Col
                xs={2}
                style={{
                  fontSize: 'calc(14px + 3vmin)',
                  marginTop: 'calc(5px - 1vmin)',
                  cursor: 'pointer'
                }}
                onClick={toggleSettings}>
                {openSettings ? <AiTwotoneSetting /> : <AiOutlineSetting />}
              </Col>
            </Row>
          </div>
              <SettingsMenu
                open={openSettings}
                color={color}
                nav={nav}
                state={state}
                exit={()=>{
                    this.setState({openSettings: false });
                }}
                />
      </div>
    )
  }
}
