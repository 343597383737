import React from 'react';
import Progress from 'react-progressbar'
import { Row, Col, Visible } from 'react-grid-system';

function SecondsCounter(props){
  var secs = Math.ceil((props.time / 1000));
  if(props.time < 0 || isNaN(secs)){
    return (
      <span  style={{color: props.color}}>{'0s'}</span>
    )
  }
  return (
    <span  style={{color: props.color}}>{ secs+ 's'}</span>
  )
}

function Timer(props){
  var prog = props.progressbar;
  if(prog < 0){
    prog = 0;
  }
  if(prog > 100){
    prog = 100;
  }
  return (
    <Row style={{paddingLeft: 0, paddingRight: 0}}>
      <Visible lg xl>
        <Col xs={11}>
          <Progress completed={prog} color={props.color}/>
        </Col>
      </Visible>

      <Visible xs sm md>
        <Col xs={10}>
          <Progress completed={prog} color={props.color}/>
        </Col>
      </Visible>
      <Col xs={1}>
        <SecondsCounter time={props.time} color={props.color}/>
      </Col>
    </Row>
  );
}

class Ticker extends React.Component {
  state={
    msLimit: 10000,
    msLeft: 0,
    endTime: 0,
    progressbar: 100
  }

  componentDidMount(){
    const { timeLimit } = this.props;
    var msLimit = timeLimit * 1000;
    var startTime = Date.now();
    var endTime = (msLimit) + startTime;
    this.setState({msLimit, endTime},
    () => {
      this.tick = this.tick.bind(this);
      this.tick();
    });
  }
  tick(){
      var now = Date.now();
      var timeLeft = this.state.endTime - now;
      var progressbar = ( timeLeft / this.state.msLimit ) * 100;
      // set up timer

      if(timeLeft < 0){
        this.setState({timeLeft, progressbar});
        this.props.onTimeUp();
      }
      else{
        this.timer = setTimeout(() => {
          this.setState({timeLeft, progressbar});
          this.tick();
        }, 100);
      }

  }
  componentWillUnmount(){
      // remove timer
      clearTimeout(this.timer);
  }

  render(){
    const { progressbar, timeLeft } = this.state;
    const { color } = this.props;

    return (
      <Timer progressbar={progressbar} time={timeLeft} color={color}/>
    );
  }
}

export default Ticker;
