import React from 'react';
import '../../App.css';

export default function NewInput(props){
    const { placeholder, multi, onChange, value, autoFocus, style } = props;
    return (
      <div style={{marginTop: 15}}>
        {!multi &&
          <input
            autoFocus={autoFocus}
            value={value}
            onChange={onChange}
            placeholder={placeholder || ''}
            style={style || {}}
            />
        }
        {multi &&
          <textarea
            autoFocus={autoFocus}
            rows= '3'
            value={value}
            onChange={onChange}
            placeholder={placeholder || ''}
            style={style || {}}
            />
        }
      </div>
    );
}
