import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import Prompt from './ui/Prompt';
import Response from './ui/Response';
import Nametag from './ui/Nametag';
import Ticker from './ui/Ticker';
import '../App.css';
import Button from './ui/Button';
import { Row, Col } from 'react-grid-system';
import { BsFillHeartFill as Heart } from 'react-icons/bs';
import { scoreboardReady, startPromptPhase } from '../firebase.js';

export default class Scoreboard extends React.Component {
  state = {
    roundResponses: {},
    prompt: '',
    judge: {
      id: '',
      color: '',
      name: ''
    },
    nextJudge: {
      id: '',
      color: '',
      name: ''
    },
    winner: {
      id: '',
      color: '',
      name: ''
    },
    playerScores: [],
    timesUp: false,
    expanded: false
  }

  sortByWins(object){
    let array = Object.values(object);
    function compare( a, b ) {
      if ( a.wins < b.wins ){
        return 1;
      }
      if ( a.wins > b.wins ){
        return -1;
      }
      //if wins tied, sort by favs
      if ( a.favs < b.favs ){
        return 1;
      }
      if ( a.favs > b.favs ){
        return -1;
      }
      return 0;
    }
    array.sort( compare );
    return array;
  }

  sortByFavs(object){
    let array = Object.values(object);
    function compare( a, b ) {
    }
    array.sort( compare );
    return array;
  }

  countFavorites(playerID, favorites){
    var favs = 0;
    console.log('COUNTING FAVORITES FOR ' + playerID);
    favorites.forEach((fav, i) => {
      console.log(fav)
      if(fav === playerID){
        console.log('MATCH! +1')
        favs++;
      }
    });
    return favs;
  }

  setJudge(){
    const { game } = this.props;
    const judgeID = game.status.judge;
    const judge = game.players[judgeID];
    this.setState({judge});
  }

  setRoundResponses(){
    const { game } = this.props;
    const responses = game.response.submissions;
    const players = game.players;
    const favorites =
      game.judge.favorites ?
      Object.values(game.judge.favorites) :
      [];
    const winnerID = game.judge.winner;

    if(Object.keys(responses).length === 0){
      this.props.nav('Lobby');
    }
    var roundResponses = {};
    for (var playerID in responses) {
      if (responses.hasOwnProperty(playerID)) {
        var response = Object.assign({}, players[playerID]);
        var favs = this.countFavorites(playerID, favorites);
        response.response = responses[playerID];
        response.winner = playerID === winnerID;
        response.favs = favs;
        roundResponses[playerID] = response;
      }
    }

    this.setState({roundResponses});
  }

  setPrompt(){
    const prompt = this.props.game.prompt.prompt;
    this.setState({prompt});
  }

  setPlayerScores(){
    const { players, game } = this.props;
    const score = game.scoreboard.score;

    var playerScores = [];
    for (var playerID in players) {
      if (players.hasOwnProperty(playerID)) {
        var playerScore = Object.assign({}, players[playerID], score[playerID]);
        playerScores.push(playerScore);
      }
    }
    playerScores = this.sortByWins( playerScores );
    this.setState({playerScores});
  }

  setNextJudge(){
    const { players, game } = this.props;
    const status = this.props.game.status;
    if(Object.keys(players).length > 0){
      function determineNextJudge(currentJudgeID){
        var judgeQueueKeys = Object.keys(status.judgeQueue);
        var judgeQueueValues = Object.values(status.judgeQueue);
        var nextJudgeIndex = judgeQueueValues.indexOf(currentJudgeID) + 1;
        if(nextJudgeIndex === judgeQueueValues.length) nextJudgeIndex = 0;
        var nextJudgeKey = judgeQueueKeys[nextJudgeIndex];
        var nextJudgeID = status.judgeQueue[nextJudgeKey];
        if(Object.keys(players).includes(nextJudgeID)){
          var nextJudge = players[nextJudgeID];
          return nextJudge;
        }
        else{
          return determineNextJudge(nextJudgeID);
        }
      }

      const nextJudge = determineNextJudge(status.judge);
      this.setState({nextJudge});
    }
  }

  setWinner(){
    const { game } = this.props;
    const winnerID = this.props.game.judge.winner;
    const winner = game.players[winnerID];
    this.setState({winner});
  }

  nextPhase(){
    const { game, players, gameID } = this.props;
    const ready = game.scoreboard.ready ? Object.keys(game.scoreboard.ready) : [];
    const playersArray = players ? Object.values(players) : [];
    if(
      (ready.length > 0 && ready.length === playersArray.length)
      //|| this.state.timesUp
    ){
      startPromptPhase(gameID);
    }
  }

    /**
      setTimer is being hidden because ir
      auto sent players to lobby

      This is being replaced with a system that give players option
      to skip other plyers turns

      This may be brought back as an optional Game Setting
    **/

    /**
  setTimer(){
    const { scoreboard, settings } = this.props.game;
    if(parseInt( scoreboard.timestamp, 10 ) > 0){

      console.log('timestamp: ' + parseInt( scoreboard.timestamp, 10 ));
      console.log('maxScoreboardTime: ' + parseInt( settings.maxScoreboardTime, 10 ));

      const maxTime =
        parseInt( scoreboard.timestamp, 10 ) + parseInt( settings.maxScoreboardTime, 10 );

      var timeLeft = maxTime - Date.now();

      console.log('maxTime: ' + maxTime);
      console.log('timeLeft: ' + timeLeft);

      if(timeLeft > 0){
        this.timer = setTimeout(
          () => {
            this.setState({timesUp: true});
          },
          timeLeft
        );
      }
      else{
        this.setState({timesUp: true});
      }
    }
  }
  **/

  componentDidMount(){
    this.setJudge = this.setJudge.bind(this);
    this.setRoundResponses = this.setRoundResponses.bind(this);
    this.setPrompt = this.setPrompt.bind(this);
    this.setPlayerScores = this.setPlayerScores.bind(this);
    this.setNextJudge = this.setNextJudge.bind(this);
    this.setWinner = this.setWinner.bind(this);
    this.nextPhase = this.nextPhase.bind(this);
    //this.setTimer = this.setTimer.bind(this);
    this.setJudge();
    this.setRoundResponses();
    this.setPrompt();
    this.setPlayerScores();
    this.setNextJudge();
    this.setWinner();
    this.nextPhase();
    //this.setTimer();
  }

  componentDidUpdate(prevProps, prevState){

    if (
      JSON.stringify(prevProps.game.scoreboard.ready) !==
      JSON.stringify(this.props.game.scoreboard.ready)
      ||
      JSON.stringify(prevProps.players) !==
      JSON.stringify(this.props.players)
      //||
      //prevState.timesUp !== this.state.timesUp
    ) {
      this.nextPhase();
    }

    if(prevProps.game.prompt.prompt !== this.props.game.prompt.prompt){
      this.setPrompt();
    }
    if(prevProps.game.judge.winner !== this.props.game.judge.winner){
      this.setWinner();
    }
    if(
      JSON.stringify(prevProps.players) !==
      JSON.stringify(this.props.players)
    ){
      this.setPlayerScores();
      this.setNextJudge();
    }
    if(
      JSON.stringify(prevProps.game.scoreboard.score) !==
      JSON.stringify(this.props.game.scoreboard.score)
    ){
      this.setPlayerScores();
    }
    if(
      JSON.stringify(prevProps.game.players) !==
      JSON.stringify(this.props.game.players)
    ){
      this.setJudge();
      this.setRoundResponses();
    }
    if(prevProps.game.status.judge !== this.props.game.status.judge){
      this.setJudge();
      this.setNextJudge();
    }
    if(
      JSON.stringify(prevProps.game.status.judgeQueue) !==
      JSON.stringify(this.props.game.status.judgeQueue)
    ){
      this.setNextJudge();
    }
    if(
      JSON.stringify(prevProps.game.response.submissions) !==
      JSON.stringify(this.props.game.response.submissions)
    ){
      this.setRoundResponses();
    }
    if(
      JSON.stringify(prevProps.game.judge.favorites) !==
      JSON.stringify(this.props.game.judge.favorites)
    ){
      this.setRoundResponses();
    }
    if(prevProps.game.judge.winner !== this.props.game.judge.winner){
      this.setRoundResponses();
    }
  }

  render(){
    const {
      roundResponses,
      prompt,
      judge,
      nextJudge,
      winner,
      playerScores,
      expanded
    } = this.state;
    const { player, game, gameID } = this.props;
    const { color } = player;
    const ready = game.scoreboard.ready ?
      Object.keys(game.scoreboard.ready):
      [];

    if( roundResponses && prompt && judge ){
      return (
        <div>
          <Ticker
            timeLimit={30}
            onTimeUp={()=>{
              if(!ready.includes(player.id)){
                scoreboardReady(gameID, player);
              }
            }}
            color={color}
            />
          <Heading color={expanded ? judge.color : winner.color}>
              {expanded ?
                'Judge ' + judge.name :
                winner.name + ' won!'
              }
            </Heading>
            <Prompt color={judge.color} prompt={prompt} />

            {winner && winner.id !== '' &&
              <RoundResponse
                roundResponse={roundResponses[winner.id]}
                expanded={expanded}
                />
            }
            {expanded &&
              <div>
                {this.sortByFavs(Object.values(roundResponses)).map(response => (
                  <div
                    key={response.id}>
                    {winner.id !== response.id &&
                      <RoundResponse
                        roundResponse={response}
                        expanded={expanded}
                        />
                    }
                  </div>
                ))}
              </div>
            }
            <Button
              link
              color={color}
              onClick={()=>{
                this.setState({expanded: !expanded})
              }}>
              {expanded ?
                'see less' :
                'see more'
              }
            </Button>
            <div id='scoreboard'>
              <Row>
                <Col xs={7}>
                  <p className='scoreboardHeader' style={{marginLeft: 38}}>
                    Name
                  </p>
                </Col>
                <Col xs={2}>
                  <p className='scoreboardHeader' style={{textAlign: 'right'}}>
                    Favs
                  </p>
                </Col>
                <Col xs={2}>
                  <p className='scoreboardHeader' style={{textAlign: 'right'}}>
                    Wins
                  </p>
                </Col>
              </Row>


              {playerScores.map((playerScore) => (

                <Row key={playerScore.id}>
                  <Col xs={7}>
                    <Nametag
                      scoreboard
                      ready={ready.includes(playerScore.id)}
                      color={playerScore.color}
                      name={playerScore.name}
                      />
                  </Col>
                  <Col xs={2}>
                    <p
                      className='scoreboard'
                      style={{color: playerScore.color, textAlign: 'right'}}
                      >
                      {playerScore.favs}
                    </p>
                  </Col>
                  <Col xs={2}>
                    <p
                      className='scoreboard'
                      style={{color: playerScore.color, textAlign: 'right'}}
                      >
                      {playerScore.wins}
                    </p>
                  </Col>
                </Row>

              ))}
            </div>
            <SubHeading
              color={nextJudge.color}
              style={{textAlign: 'center', fontWeight: 200}}
              >
              {nextJudge.id === player.id ?
                'You are the next judge!' :
                nextJudge.name + ' is the next judge!'
              }
            </SubHeading>
            <br />
            <Button
              outline={!ready.includes(player.id)}
              color={color}
              onClick={()=>{
                if(!ready.includes(player.id)){
                  scoreboardReady(gameID, player);
                }
              }}
              >
              {ready.includes(player.id) ? 'Oh You\'re Ready Now': 'Ready'}
            </Button>
          </div>
        );

    }
    else{
      return (<div>
        {
          console.log(
            'ERROR:\n'+
            (roundResponses && prompt && judge && winner) +'\n'+
            'roundResponses:\n' + JSON.stringify(roundResponses) +'\n'+
            'prompt:\n' + prompt +'\n'+
            'judge:\n' + JSON.stringify(judge) +'\n'+
            'winner:\n' + JSON.stringify(winner) +'\n'+
            JSON.stringify(this.state)
          )
        }
      </div>);
    }
  }
}

class RoundResponse extends React.Component {
  render(){
    const {
      id,
      color,
      name,
      response,
      winner,
      favs
    } = this.props.roundResponse;
    const { expanded } = this.props;

    return (
        <div>
          <Response
            selected={winner}
            color={color}
            onClick={()=>{}}
          >
            {response}
          </Response>
          {expanded &&
          <div style={{margin:'0 10px 20px 10px'}}>
            <span style={{color, fontWeight: 700}}>
              {winner ? 'Winner: ' + name : name}
            </span>
            <span style={{color, float: 'right'}}>
              <span style={{position: 'relative', top: -5}}>
                {favs + ' '}
              </span>
                <Heart/>
            </span>
          </div>
        }
        </div>
    )
  }
}
