import React from 'react';
import ChangeNameColor from './ChangeNameColor';
import Lobby from './Lobby';
import Prompt from './PromptPhase';
import Response from './ResponsePhase';
import Judge from './JudgePhase';
import Scoreboard from './ScoreboardPhase';
import GameSettings from './GameSettings';
import VoteKickMenu from './VoteKickMenu';
import Help from './Help';
import ReportIssue from './ReportIssue';
import Button from './ui/Button';
import KickPlayerDropdown from './ui/KickPlayerDropdown';
import AnimateHeight from 'react-animate-height';
import { Row, Col } from 'react-grid-system';
import {
  leaveGame,
  setGameListener,
  resetGame,
  resetKickVote,
  voteKickBallot
} from '../firebase';

export default class Game extends React.Component {
  state={
    players: {},
    game: {
      dateCreated: '',
      timestamp: '',
      settings: {
        maxPlayers: 0,
        maxPromptTime: 120000,
        maxResponseTime: 120000,
        minJudgeTime: 15000,
        maxJudgeTime: 60000,
        maxScoreboardTime: 30000
      },
      status: {
        phase: 'Lobby',
        round: 0,
        judge: '',
        judgeQueue: {},
      },
      players: {
      },
      lobby: {
        host: '',
        ready: {}
      },
      prompt: {
        round: 0,
        ready: '',
        prompt: '',
        timestamp: '',
        extraTime: false
      },
      response: {
        round: 0,
        submissions: {},
        activity: {},
        timestamp: ''
      },
      judge: {
        round: 0,
        favorites: {},
        winner: '',
        timestamp: ''
      },
      scoreboard: {
        round: 0,
        score: {},
        ready: {},
        timestamp: ''
      }
    },
    pageNames: [
      'ChangeNameColor',
      'VoteKickMenu',
      'GameSettings',
      'ReportIssue',
      'Help'
    ],
    phaseNames: [
      'Lobby',
      'Prompt',
      'Response',
      'Judge',
      'Scoreboard'
    ]
  }

  gameListener(game) {
    this.setState({game});
  }

  gameNotFound() {
    const { gameID, nav } = this.props;
    localStorage.setItem( 'error', 'Game ID: ' + gameID + ' does not exist');
    window.location.pathname = '/';
    nav('Home');
  }

  async componentDidMount() {
    const { gameID, nav, page } = this.props;
    const { name } = this.props.player;
    const { pageNames, phaseNames } = this.state;

    this.gameListener = this.gameListener.bind(this);
    this.gameNotFound = this.gameNotFound.bind(this);

    await setGameListener(gameID, this.gameListener, this.gameNotFound);

    //redirect to lobby if the page name is incorrect
    if(!pageNames.includes(page) && !phaseNames.includes(page)){
      nav('Lobby');
    }
    //redirect to change name if no name found
    if( name == '') {
      nav('ChangeNameColor');
    }
  }

  componentDidUpdate(prevProps, prevState){
    const { game } = this.state;

    if(
      prevState.game.status.phase !== game.status.phase
      ||
      JSON.stringify(prevState.game.lobby.ready) !==
      JSON.stringify(game.lobby.ready)
      ||
      prevProps.page !== this.props.page
    ){

      //if phase updates send player to phase if they are ready
      //unless player is not currently in a game phase
      const ready = game.lobby.ready ? Object.keys(game.lobby.ready) : [];
      const phase = game.status.phase;
      const { player, nav, gameID, page } = this.props;
      const { pageNames, phaseNames } = this.state;

      if(!pageNames.includes(page) && phaseNames.includes(page)){
        if(ready.includes(player.id)){
          nav(phase);
        }
        else{
          nav('Lobby');
        }
      }

      //game is valid if it was made within last 6 hours
      if(game.timestamp && game.timestamp !== ''){
        const sixHours = 1000 * 60 * 60 * 6;
        const sixHoursAgo = Date.now() - sixHours;
        const sixHoursOld = game.timestamp < sixHoursAgo;
        if( sixHoursOld ){
          leaveGame(gameID, player);
        }
      }

    }

    if(
      JSON.stringify(prevState.game.lobby.ready) !==
      JSON.stringify(game.lobby.ready)
    ){

      //update the state.players object with only players in lobbyReady
      const ready = game.lobby.ready ? Object.keys(game.lobby.ready) : [];
      var players = {};
      ready.forEach(playerID => {
        players[playerID] = game.players[playerID]
      });
      this.setState({players});
    }

    if(
      JSON.stringify(game.players) !==
      JSON.stringify(prevState.game.players)
    ){
      //--
      if(
        Object.keys(game.players).length < 3 &&
        game.status.round !== 0
      ){
        resetGame(this.props.gameID)
      }
    }

    if(
      game.leaving &&
      JSON.stringify(game.leaving) !==
      JSON.stringify(prevState.game.leaving)
    ){
      const { player } = this.props;
      const leaving = Object.keys(game.leaving);
      //if the joined game in localStorage does not match the gameID,
      //the player must have successfully left the game at some point.
      const joinedGame = localStorage.getItem('joinedGame');
      const playerIsLeaving =
        leaving.includes(player.id) && joinedGame === this.props.gameID;
      console.log(JSON.stringify(leaving))
      console.log('joined: ' + joinedGame)

      if( playerIsLeaving ){
        window.location.pathname = '/';
      }
    }
  }

  render(){
    const {
      gameID,
      page,
      nav,
      player,
      setName,
      changeColor
    } = this.props;
    const { game, players } = this.state;

    return (
      <div>
        <KickPlayerDropdown
          gameID={gameID}
          game={game}
          player={player}
          />
        {page === 'ChangeNameColor' &&
            <ChangeNameColor
              onContinue={() => {
                nav('Lobby');
              }}
              color={player.color}
              setName={setName}
              changeColor={changeColor}
              name={player.name}
              />
        }
        {page === 'GameSettings' &&
          <GameSettings
            gameID={gameID}
            player={player}
            nav={nav}
            game={game}
            />
        }
        {page === 'Lobby' &&
          <Lobby
            gameID={gameID}
            player={player}
            nav={nav}
            game={game}
            />
        }
        {page === 'Prompt' &&
          <Prompt
            gameID={gameID}
            player={player}
            nav={nav}
            game={game}
            players={players}
            />
        }
        {page === 'Response' &&
          <Response
            gameID={gameID}
            player={player}
            nav={nav}
            game={game}
            players={players}
            />
        }
        {page === 'Judge' &&
          <Judge
            gameID={gameID}
            player={player}
            nav={nav}
            game={game}
            players={players}
            />
        }
        {page === 'Scoreboard' &&
          <Scoreboard
            gameID={gameID}
            player={player}
            nav={nav}
            game={game}
            players={players}
            />
        }
        {page === 'VoteKickMenu' &&
          <VoteKickMenu
            gameID={gameID}
            player={player}
            nav={nav}
            game={game}
            players={players}
            />
        }


        {page === 'Help' &&
            <Help
              onContinue={() => {
                nav('Lobby');
              }}
              color={player.color}
              />
        }
        {page === 'ReportIssue' &&
            <ReportIssue
              onContinue={() => {
                nav('Lobby');
              }}
              color={player.color}
              meta={{
                gameID,
                game,
                page,
                player
              }}
              />
        }
      </div>
    )
  }
}
