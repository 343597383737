import React from 'react';
import { Heading, SubHeading } from './ui/Headings';
import ColorPicker from './ui/ColorPicker';
import '../App.css';
import Input from './ui/Input';
import Button from './ui/Button';

export default class ChangeNameColor extends React.Component {
  state = {
    name: ''
  }

  componentDidMount(){
    this.setState({name: this.props.name});
    console.log('mount: ' + this.state.name);
  }

  render(){
    const { color, onContinue, setName, changeColor } = this.props;
    return (
      <div>
          <SubHeading color={color}>Choose Your Name</SubHeading>
          <Input
            placeholder='Nickname'
            value={this.state.name}
            onChange={ (e) => {
              this.setState({name: e.target.value});
              console.log('onChange: ' + e.target.value);
            }}/>
          <SubHeading color={color}>Choose Your Color</SubHeading>
          <ColorPicker
            color={ color }
            changeColor={ changeColor }
            />
          <Button style={{marginTop:5}} color={color} onClick={ () => {
              setName(this.state.name);
              onContinue();
            }}> Continue </Button>

        </div>
    );
  }
}
