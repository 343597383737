import React from 'react';
import '../../App.css';
import { leaveGame, lobbyUnReady } from '../../firebase.js'
import AnimateHeight from 'react-animate-height';

export default class SettingsMenu extends React.Component{

  state = {
    disableToLobby: true
  }

  setReturnToLobby(){
    const {page} = this.props.state;
    if(page === 'ChangeNameColor'){
      return this.setState({disableToLobby: true});
    }
    else if(page === 'Home'){
      return this.setState({disableToLobby: true});
    }
    else if(page === 'Lobby'){
      return this.setState({disableToLobby: true});
    }
    else{
      return this.setState({disableToLobby: false});
    }
  }

  componentDidMount(){
    this.setReturnToLobby.bind(this);
    this.setReturnToLobby();
  }

  componentDidUpdate(prevProps){
    if(prevProps.state.page !== this.props.state.page){
      this.setReturnToLobby();
    }
  }

  render(){
    const {color, nav, state, exit, open} = this.props;
    const player = {
      color: state.color,
      name: state.name,
      id: state.id
    };
    const { page, gameID } = state;
    const inGame = gameID != null && gameID !== '';
    const { disableToLobby } = this.state;

    return (
      <AnimateHeight
        duration={ 500 }
        height={ open ? 'auto' : 0 }
        >
        <div style={{
            boxShadow: '0 5px 10px black'
          }}>
          <MenuItem
            visible={true}
            color={color}
            exit={exit}
            onClick={()=>{
              nav('ReportIssue');
            }}
            disabled={false}
            >
            Report An Issue
          </MenuItem>
          <MenuItem
            visible={true}
            color={color}
            exit={exit}
            onClick={()=>{
              nav('Help');
            }}
            disabled={false}
            >
            Help
          </MenuItem>
          <MenuItem
            visible={inGame}
            color={color}
            exit={exit}
            onClick={()=>{
              nav('VoteKickMenu');
            }}
            disabled={false}
            >
            Vote To Kick
          </MenuItem>
          <MenuItem
            visible={inGame}
            color={color}
            exit={exit}
            disabled={disableToLobby}
            onClick={()=>{
              lobbyUnReady(gameID, player);
              nav('Lobby');
            }}
            >
            Back To Lobby
          </MenuItem>
          <MenuItem
            visible={inGame}
            color={color}
            exit={exit}
            disabled={false}
            onClick={()=>{
              window.location.pathname = '';
              leaveGame(gameID, player, (success) => {
                if( success === gameID ){
                  console.log('Left Game');
                }
              });
            }}>
            Leave Game
          </MenuItem>
          <MenuItem
            visible={!inGame}
            color={color}
            exit={exit}
            onClick={()=>{
              localStorage.clear();
              window.location.reload();
            }}
            disabled={false}
            >
            Clear Cache
          </MenuItem>
        </div>
      </AnimateHeight>
    )
  }
}

class MenuItem extends React.Component{
  render(){
    const {children, color, onClick, disabled, exit, visible} = this.props;
    return (
      <div>
        {visible &&
          <div
            className={disabled ? 'AppSettingDisabled' : 'AppSetting'}
            style={{
              '--color': color,
              '--color-light': color + 'CC',
              '--color-gray': color + '80',
            }}
            onClick={
              disabled ?
              ()=>{} :
              ()=>{
                exit();
                onClick();
              }

            }>
           {children}
          </div>
        }
      </div>
    )
  }
}
