import React from 'react';
import './GameIDInput.css';

export default class GameIDInput extends React.Component {
  render(){
    const { color, value, style, label, autoFocus } = this.props;
    var { onChange, onSubmit } = this.props;
    if(onChange === undefined){
      onChange = ()=>{}
    }
    if(onSubmit === undefined){
      onSubmit = ()=>{}
    }
    return(
        <div style={Object.assign({marginTop: 10}, style)}>
          <span
            id='GameID'
            style={{ color, cursor: 'default' }}
          >
            {label ? label : 'Game ID:'}
          </span>
          <input
            id='GameIDInput'
            style={{ borderColor: color }}
            onFocus={(e) => {e.target.select()}}
            onChange={onChange}
            value={value}
            autoFocus={autoFocus}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                // Enter Buttom Clicked
                e.preventDefault();
                onSubmit();
              }
            }}
          />
        </div>

    )
  }
}
